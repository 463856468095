import { createPreferences } from 'api/graphql/mutation';
import { getPreferences } from 'api/graphql/query';
import AppStore from 'container/app/appStore';
import { action, observable } from 'mobx';

export type AllFavoriteMetadata = FavoriteMetadata[];

export interface FavoriteMetadata {
  entityId: string,
  entityType: string,
  id?: string,
  title: string,
  description?: string,
}

export class MyOraganizationStore {
  @observable appStore: AppStore;
  @observable allFavoriteMetadata: AllFavoriteMetadata = [];
  @observable entity: any;
  @observable myOrgId = '';

  constructor(appStore: AppStore) {
    this.appStore = appStore;
  }

  @action
  init() {
    this.getMyOrg();
  }

  @action
  onOrgClick(isMyOrg: boolean, entityId: string): void {
    this.updateMyOrg(isMyOrg ? '' : entityId);
  }

  @action
  isMyCurrentOrg(entityId): boolean {
    return this.myOrgId === entityId;
  }

  /**
   * Gets initial data on what has already been favorited.
   * @returns {string} Promise from API call.
   */
  @action
  async getMyOrg(): Promise<any> {
    await this.appStore.api.query(getPreferences, {})
      .then(action(({ data }) => {
        const preferences = JSON.parse(data.getPreferences);
        this.myOrgId = preferences?.organizationUrl;
      }))
      .catch(() => {
        this.createToastAlert('Failed to get my org url. Please try again later.');
      });
  }

  @action
  updateMyOrg(entityId): void {
    const params = {
      organizationUrl: entityId,
    };
    this.appStore.api.mutate(createPreferences, params)
      .then(action(() => {
        this.myOrgId = entityId;
        this.appStore.appTopNavStore.organizationUrl = this.myOrgId;
        this.appStore?.appToastAlertStore.addToastAlert(
          {
            statusCode: '200',
            message: this.myOrgId.length ? 'Successfully set current folder as My Organization' : 'Successfully removed current folder from My Organization',
          },
          'success'
        );
      }));
  }

  /**
   * Generates an alert for the user.
   * @param {string} message Message displayed in the alert.
   * @param {string} status Alert type
   * @param {string} statusCode Alert code.
   */
  createToastAlert(message: string, status = 'error', statusCode = '400'): void {
    this.appStore.appToastAlertStore.addToastAlert({
      message,
      statusCode,
    }, status);
  }
}

export default MyOraganizationStore;
