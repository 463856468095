import { matchPath } from 'react-router-dom';

export const NAVIGATION = {
  LANDING_PAGE: {
    PATH: '/',
    MATCH: '/',
    LINK: folderPath => `/#folderId=${folderPath}`,
  },
  DASHBOARD_PAGE: {
    PATH: '/folder/:folderId*',
    ALIAS_PATH: '/org/:folderAlias',
    LINK: folderPath => `/folder/${folderPath}`,
    ALIAS_LINK: folderAlias => `/org/${folderAlias}`,
  },
  DOCUMENT_PAGE: {
    PATH: '/docs/:documentId/:documentStatus/:processId?',
    LINK: (documentId, documentStatus) => `/docs/${documentId}/${documentStatus}`,
    REVIEW_LINK: (documentId, documentStatus, processId) => `/docs/${documentId}/${documentStatus}/${processId}`,
    MATCH: '/docs/',
    STATUS: {
      DRAFT: 'draft',
      PUBLICATION: 'publication',
      REVIEW: 'review',
      TRANSCLUDE_BULK_REVIEW: 'transcludeBulkReview',
      TRANSCLUDE_PREVIEW: 'transcludePreview',
    },
  },
  INTAKE_FORM_PAGE: {
    PATH: '/intake/:documentId/:documentStatus/:processId?',
    LINK: (documentId, documentStatus) => `/intake/${documentId}/${documentStatus}`,
    MATCH: '/intake/',
    STATUS: {
      DRAFT: 'draft',
      PUBLICATION: 'publication',
      REVIEW: 'review',
    },
  },
  NEW_INTAKE_PAGE: {
    PATH: '/intake/new/:documentId?',
    LINK: documentId => `/intake/new/${documentId}`,
    MATCH: '/new/',
  },
  // NEW_SUPPLEMENTAL_PAGE: {
  //   PATH: '/docs/:documentId/supplemental/:supplementalTag/draft',
  //   LINK: (documentId, supplementalTag, documentStatus) => `/docs/${documentId}/supplemental/${supplementalTag}/${documentStatus}`,
  //   MATCH: '/docs/',
  // },
  // EXISTING_SUPPLEMENTAL_PAGE: {
  //   PATH: '/docs/:documentId/supplemental/:supplementalTag/publication',
  //   LINK: (documentId, supplementalTag, documentStatus) => `/docs/${documentId}/supplemental/${supplementalTag}/${documentStatus}`,
  //   MATCH: '/docs/',
  // },
  NEW_SUPPLEMENTAL_PAGE: {
    PATH: '/docs/:documentId/draft?supplemental=:supplementalTag',
    LINK: (documentId, supplementalTag) => `/docs/${documentId}/draft?supplemental=${supplementalTag}`,
    MATCH: '/docs/',
  },
  EXISTING_SUPPLEMENTAL_PAGE: {
    PATH: '/docs/:documentId/publication?supplemental=:supplementalTag',
    LINK: (documentId, supplementalTag) => `/docs/${documentId}/publication?supplemental=${supplementalTag}`,
    MATCH: '/docs/',
  },
  TRANSCLUDE_PREVIEW_PAGE: {
    PATH: '/docs/:transcludeId/transcludePreview?dependentDocId=:dependentDocumentId',
    LINK: (documentId, dependentDocumentId) => `/docs/${documentId}/transcludePreview?dependentDocId=${dependentDocumentId}`,
    MATCH: '/docs/',
  },
  TRANSLATION_PAGE: {
    PATH: '/docs/:documentId/publication?translation=:translationTag',
    LINK: (documentId, documentStatus, translationTag) => `/docs/${documentId}/${documentStatus}?translation=${translationTag}`,
    MATCH: '/docs/',
  },
  SUPPLEMENTAL_REVIEW_PAGE: {
    PATH: '/docs/:documentId/review?supplemental=:supplementalTag',
    LINK: (documentId, supplementalTag) => `/docs/${documentId}/review?supplemental=${supplementalTag}`,
    MATCH: '/docs/',
  },
  FOLDER_MANAGEMENT_PAGE: {
    PATH: '/folder/:folderId/manage',
    LINK: folderId => `/folder/${folderId}/manage`,
    MATCH: '/folder/',
  },
  PROCESS_PAGE: {
    PATH: '/process/:folderId/manage/:templateId',
    LINK: (folderId, templateId) => `/process/${folderId}/manage/${templateId}`,
    MATCH: '/process/',
  },
  EXCEPTION_NOTIFICATION_PROCESS_PAGE: {
    PATH: '/process/notification/:folderId/manage/:templateId',
    LINK: (folderId, templateId) => `/process/notification/${folderId}/manage/${templateId}`,
    MATCH: '/process/',
  },
  SIMPLE_SEARCH_PAGE: {
    PATH: '/search/',
    MATCH: '/search/',
    LINK: (query, rootFolderId) => (rootFolderId.length > 0 ? `/search/?query=${query}&rootFolder=${rootFolderId}` : `/search/?query=${query}`),
  },
  TASKBOARD_PAGE: {
    PATH: '/tasks/:taskType',
    MATCH: '/tasks/',
    LINK: taskType => `/tasks/${taskType}`,
  },
  ACKNOWLEDGMENTS_PAGE: {
    PATH: '/acknowledgments',
    MATCH: '/acknowledgments',
  },
  BULK_ACTIONS: {
    PATH: '/bulkActions',
    MATCH: '/bulkActions',
  },
  MIGRATION_PAGE: {
    PATH: '/migration',
    MATCH: '/migration',
  },
  QUICKSIGHT_DASHBOARD: {
    PATH: 'https://idp.federate.amazon.com/api/saml2/v1/idp-initiated?providerId=Amazon-Policy-Reporting&target=https%3A%2F%2Fquicksight.aws.amazon.com',
  },
};

export const PAGES_WITH_SIDE_NAV = [
  NAVIGATION.DOCUMENT_PAGE.STATUS.DRAFT,
  NAVIGATION.DOCUMENT_PAGE.STATUS.PUBLICATION,
];

// Pass in a path to check if it is the current page.
export const isPathActive = path => (
  matchPath(window.location.pathname, {
    path,
    exact: true,
    strict: false,
  })
);
