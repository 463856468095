import { SearchSelectOptionsStore } from 'fields/selectField/searchSelectOptionsStore';
import { RadioGroupItem } from '../fields/radioGroupField/radioGroupModel';
import { SelectOption } from '../fields/selectField/selectModel';

export class EntityMetadata {
  parentFolderId: string;
  parentFolderName: string;
  canRead: boolean;
  canWrite: boolean;
  isAdmin: boolean;
  underReview: boolean;
  underBulkReview: boolean;
  parentFolders: Array<Map<string, string>>;
  secondaryParentFolders: any[];
  isPublic: boolean;
  isShared: boolean;
  isActive: boolean;
  description?: string;
  translationTag?: string;
  revokedComment?: string;
  revokedDate?: string;
  revokedBy?: string;

  constructor(metadata) {
    this.parentFolderId = metadata.parentFolderId || metadata.parentId;
    this.parentFolderName = metadata.parentFolderName || metadata.parentName;
    this.canRead = metadata.canRead || metadata.canWrite || metadata.isAdmin;
    this.canWrite = metadata.canWrite || metadata.isAdmin;
    this.isAdmin = metadata.isAdmin;
    this.underReview = metadata.underReview ? metadata.underReview : false;
    this.underBulkReview = metadata.underBulkReview ? metadata.underBulkReview : false;
    this.parentFolders = metadata.parentFolders ? metadata.parentFolders : [];
    this.secondaryParentFolders = metadata.secondaryParentFolders ? metadata.secondaryParentFolders : {};
    this.isPublic = metadata.isPublic ?? false;
    this.isShared = metadata.isShared ?? false;
    this.isActive = (metadata.documentStatus == null || metadata.documentStatus === 'active');
    this.description = metadata?.description;
    this.revokedBy = metadata.exceptionRevokedBy;
    this.revokedComment = metadata.exceptionRevokedComment;
    this.revokedDate = metadata.exceptionRevokedDate;
  }
}

export class Document {
  id: string;
  title: string;
  description: string;
  isTemplate?: boolean;
  documentType?: string;
  subSection: Section[];
  metaData?: EntityMetadata;
  documentStatus?: string;
  createdBy?: string;
  revisionComments?: string;
  lastModifiedBy?: string;
  lastModifiedOn?: string;
  retractedComment?: string;
  retractedDate?: string;
  retractedBy?: string;
  expiration?: string;
  intakeForm?: string;
  extensionForm?: string;
  closureForm?: string;
  exceptionEnabled?: boolean;
  exceptionState?: string;
  lastPublishedDate?: string;
  mainTitle?: string;
  mainDescription?: string;


  constructor(doc) {
    this.id = doc.id;
    this.title = doc.title;
    this.description = doc.description;
    this.isTemplate = doc.isTemplate;
    this.documentType = doc.documentType;
    this.subSection = JSON.parse(doc.subSection ?? '[]');
    this.createdBy = doc.created_by;
    this.lastModifiedBy = doc.lastModifiedBy;
    this.lastModifiedOn = doc.lastModifiedOn;
    this.revisionComments = doc.revisionComments;
    this.retractedComment = doc.retractedComment;
    this.retractedDate = doc.retractedDate;
    this.retractedBy = doc.retractedBy;
    this.expiration = doc.expiration;
    this.intakeForm = doc.intakeForm;
    this.extensionForm = doc.extensionForm;
    this.closureForm = doc.closureForm;
    this.exceptionEnabled = doc.exceptionEnabled;
    this.exceptionState = doc.exceptionState;
    this.lastPublishedDate = doc.lastPublishedDate;
    this.mainTitle = doc.mainTitle;
    this.mainDescription = doc.mainDescription;

    if (doc.metaData) {
      this.metaData = new EntityMetadata(JSON.parse(doc.metaData));
    }

    if (doc.version !== 0) {
      this.documentStatus = 'publication';
    } else if (this.metaData?.underReview) {
      this.documentStatus = 'under review';
    } else {
      this.documentStatus = 'draft';
    }
  }

  get rootFolderId() {
    if (this.metaData && this.metaData.parentFolders && this.metaData.parentFolders.length > 0) {
      // eslint-disable-next-line dot-notation
      return this.metaData?.parentFolders[0]['id'];
    }

    return '';
  }
}

export interface DocumentSummary {
  id: string;
  title: string;
  description: string;
  isTemplate: boolean;
  documentType: string;
  documentStatus: string;
  parentFolderName?: [];
}

export interface Attachment {
  id?: string;
  fileName: string;
  description: string;
  fileId: string;
  fileSize: string;
  createdBy: string;
  createdOn: string;
  isPublic: boolean;
  attachmentFile: Array<any>;
  malwareStatus?: string;
  diffState?: AttachmentDiffState;
}

// Used when comparing attachment changes on the Review Page.
export type AttachmentDiffState = 'ADDED' | 'DELETED' | 'IN_BOTH';

export interface Section {
  id: string;
  label: string;
  index?: number;
  sectionTitle: string;
  sectionContent: any;
  childOrdering: string[]
  subSection: Section[];

  // Frontend
  tempValue?: string;
  tempTitle?: string;
  tag?: number;
}

export interface Attribute {
  id: string
  name: string
  description: string
  type: string
  value: any
  attributeConfig: any
  customFieldStore: SearchSelectOptionsStore
  isPublicResponse?: any

  // radio group
  items?: RadioGroupItem[];

  // select field
  options?: SelectOption[];

  // read only
  previewMode?: boolean;

  required?: boolean;
  isBackDate?: boolean;
}

export interface Role {
  roleId?: string;
  roleName: string;
  actions: string[];
  description: string;
  parentEntityId?: string;
  parentEntityType?: string;
  inherited?: boolean;
}

export interface Permission {
  userAlias: string;
  inherit?: boolean;
  inherited?: boolean;
  role: Role;
}

export interface DownloadPresignedUrlResult {
  url: string;
}

export interface DownloadActionItemAttachmentUrl {
  url: string;
  fileId: string;
}

export interface UploadPresignedUrlResult {
  response: UploadResponse;
  fileId: string;
  url: string;
}

export interface UploadResponse {
  url: string;
  fields: string;
}

export enum AttributeType {
  TEXT = 'TextAttribute',
  RADIOGROUP = 'RadioGroupAttribute',
  CHECKBOX = 'Checkbox',
  TEXTAREA = 'TextAreaAttribute',
  SELECT = 'SelectAttribute',
  DATE = 'Date',
  DATERANGE = 'DateRange',
  MULTISELECT = 'MultiselectAttribute',
  SINGLEUSER = 'UserSelectAttribute',
  MULTIPLEUSERS = 'UserMultiselectAttribute',
  HEADING_ATTRIBUTE = 'HeadingAttribute',
}

export interface DocumentTypeModel {
  id: string;
  label: string;
}

export class DocumentVersion {
  name: string;
  versionName: string;
  versionNumber: string;
  lastModified: number;
  nextVersionLastModified?: number;
  nextVersionNumber?: string;

  constructor(version) {
    this.name = version.name;
    this.lastModified = version.lastModified;
    // The response from API is in the format {documentId}/publication-{version number}.json
    // the following logic is to extract the version name, which is "publication-{version number}" and version number
    const endChar = (this.name.split('-').length - 1) > 1 ? '-' : '.';
    this.versionName = this.name.substring(this.name.lastIndexOf('/') + 1, this.name.lastIndexOf(endChar));
    this.versionNumber = this.name.substring(this.name.indexOf('publication-') + 'publication-'.length, this.name.lastIndexOf('.'));
  }
}

export interface Badge {
  label?: string;
  color?: 'blue' | 'grey' | 'green' | 'red';
}


export interface Formula {
  name: string;
  formula: string;
  calculation?: number;
  riskLevel?: string;
  overriddenLabel?: string;
  overriddenValue?: number;
  diffState?: AttachmentDiffState;
  reason?: string;
  matrixId?: string;
}

export enum QuestionnaireType {
  TEXT = 'TextQuestionnaire',
  RADIOGROUP = 'RadioGroupQuestionnaire',
  CHECKBOX = 'Checkbox',
  TEXTAREA = 'TextAreaQuestionnaire',
  SELECT = 'SelectQuestionnaire',
  DATE = 'Date',
  DATERANGE = 'DateRange',
  MULTISELECT = 'MultiselectQuestionnaire',
  SINGLEUSER = 'UserSelectQuestionnaire',
  MULTIPLEUSERS = 'UserMultiselectQuestionnaire',
  HEADING_QUESTIONNAIRE = 'HeadingQuestionnaire',
}
