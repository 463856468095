/* eslint-disable max-lines*/
/* eslint-disable max-len */
import { observer } from 'mobx-react';
import React, { Suspense, lazy } from 'react';
import { Badge, Box, Button, Grid, Header, Link, Pagination, Popover, Select, SpaceBetween, TextContent, ExpandableSection, Toggle, Input, Checkbox } from '@amzn/awsui-components-react';
import { DocumentWrapper } from '../documentWrapper';
import { NAVIGATION } from 'constants/navigation';
import { Icon } from '@amzn/awsui-components-react/polaris';
import './documentTileStyles.scss';
import AppStore from 'container/app/appStore';
import { action, observable } from 'mobx';
import InfoOutlined from '@material-ui/icons/InfoOutlined';
import { DbFolderContainerStore } from '../dbFolderContainerStore';
import { DocumentSearchStore } from 'pages/search/documentSearchStore';
import LoadingSpinner from 'components/loadingSpinner/loadingSpinner';
import { EXTERNAL_LINKS } from 'constants/ExternalLinks';
import { Document } from 'api/document';
import { getFolderMetadata } from 'api/graphql/query';
import { Folder } from 'api/folder';
import CopyButton from 'pages/document/components/content/sectionLinkCopyIcon';
import MyOrganization from 'components/myOrganization/myOrganization';

const Favorites = lazy(() => import('components/favorites/favorites'));

interface Props {
  appStore?: AppStore;
  documentWrapper: DocumentWrapper;
  dbFolderContainerStore?: DbFolderContainerStore;
  documentSearchStore?: DocumentSearchStore;
  isSearch?: boolean;
  favoritesStore?;
  myOrganizationStore?;
  isLoading?: boolean;
  isLandingPage?: boolean;
  setIsLoadingDocuments?: (isLoading: boolean) => void;
  onSelectedChange?: (folder: Folder) => void;
  setActiveFolderHref?: (href: string) => void;
}

export enum DocumentTypes {
  POLICY = 'POLICY',
  PROCEDURE = 'PROCEDURE',
  GUIDELINE = 'GUIDELINE',
  STANDARD = 'STANDARD',
  TRANSCLUDE = 'TRANSCLUDE',
  OTHER = 'OTHER'
}

const docTypes = ['POLICY', 'PROCEDURE', 'STANDARD', 'GUIDELINE', 'TRANSCLUDE', 'OTHER'];

@observer
export class DocumentTile extends React.PureComponent<Props, any> {
  constructor(props) {
    super(props);
    this.state = {
      organizedData: {},
      showAll: false,
    };
  }

  toggleShowAll = () => {
    this.setState(prevState => ({ showAll: !prevState.showAll }));
  };

  // @observable isRelated: boolean
  @observable searchText = '';
  @observable documentTypesSelected: string[] = docTypes;
  @observable filterAllCheckboxEnabled = true;
  @observable filterPolicyCheckboxEnabled = true;
  @observable filterProcedureCheckboxEnabled = true;
  @observable filterGuidelineCheckboxEnabled = true;
  @observable filterStandardCheckboxEnabled = true;
  @observable filterTranscludeCheckboxEnabled = true;
  @observable filterOtherCheckboxEnabled = true;


  isDraftBadge(document: any): string {
    if (document.entityType === 'folder') return 'Folder';
    if (document.version !== 0) {
      return 'publication';
    }
    return 'draft';
  }

  @action
  componentDidUpdate(prevProps) {
    const { documentWrapper: prevDocumentWrapper } = prevProps;
    const { documentWrapper } = this.props;
    if (documentWrapper?.selectedFolder?.id !== prevDocumentWrapper?.selectedFolder?.id) {
      this.searchText = '';
      this.filterAllCheckboxEnabled = true;
      this.handleAllCheckboxClick(true);
      this.documentTypesSelected = docTypes;
    }
  }

  // Execute a new query when pagination settings change.
  getFolders(): void {
    const { appStore, documentWrapper, dbFolderContainerStore } = this.props;
    dbFolderContainerStore?.getFilesUnderFolder(
      documentWrapper!.selectedFolder!.id,
      appStore!.paginationPageNumber,
      parseInt(appStore!.paginationPageSize.value || '10'),
      this.searchText ?? '',
      this.documentTypesSelected
    );
  }

  @action.bound
  handleAllCheckboxClick(detail) {
    this.filterPolicyCheckboxEnabled = detail;
    this.filterProcedureCheckboxEnabled = detail;
    this.filterGuidelineCheckboxEnabled = detail;
    this.filterOtherCheckboxEnabled = detail;
    this.filterStandardCheckboxEnabled = detail;
    this.filterTranscludeCheckboxEnabled = detail;
  }

  @action
  handleCheckboxChange = (documentType, checked, folderId) => {
    if (checked) {
      this.documentTypesSelected.push(documentType);
    } else {
      this.documentTypesSelected = this.documentTypesSelected.filter(item => item !== documentType);
    }
    if (!checked) {
      this.filterAllCheckboxEnabled = checked;
    }
    this.handleAllCheckbox();
    this.searchDocuments(folderId);
  };

  @action.bound
  handleAllCheckbox() {
    if (this.documentTypesSelected.length === docTypes.length) {
      this.filterAllCheckboxEnabled = true;
    }
  }

  // Execute a new search when pagination settings change.
  runSearch(): void {
    const { documentSearchStore } = this.props;
    documentSearchStore?.onSearch();
  }

  @action.bound
  handleEditClick() {
    const { appStore, documentWrapper } = this.props;
    appStore!.navigateTo(NAVIGATION.FOLDER_MANAGEMENT_PAGE.LINK(documentWrapper.selectedFolder?.id));
  }

  /**
   * Handles click on any favorited folders.
   * @param {Document} folder The folder that was clicked on.
   */
  handleFavoriteFolderClick(folder: Document): void {
    const { dbFolderContainerStore, onSelectedChange, appStore, setIsLoadingDocuments, setActiveFolderHref } = this.props;
    const [folderId] = folder.id.toString().split('/');
    if (setIsLoadingDocuments) setIsLoadingDocuments(true);

    appStore?.api.query(getFolderMetadata, { folderId })
      .then(action(value => {
        const path: string[] = [];
        // this.props.appStore?.isRelated = value.data?.getFolderMetadata?.isRelated
        const selected = new Folder(value.data?.getFolderMetadata);
        selected?.parentFolders?.forEach(element => {
          path.unshift(element.id);
        });
        path.unshift(folderId);
        dbFolderContainerStore?.performFolderSelect('', path);
        if (onSelectedChange) onSelectedChange(selected);
        // Set folder to be active and redirect to org view
        if (this.props.isLandingPage) {
          if (setActiveFolderHref) setActiveFolderHref(`#folderId=${path.reverse().join('/')}`);
          appStore!.navigateTo(NAVIGATION.LANDING_PAGE.LINK(path.reverse().join('/')));
        } else {
          const activeHref = path.reverse().join('/');
          if (setActiveFolderHref) setActiveFolderHref(activeHref);
          // If favorite folder alias defined, redirect to pretty URL
          if (selected.folderAlias && selected.folderAlias.length > 0) appStore!.navigateTo(NAVIGATION.DASHBOARD_PAGE.ALIAS_LINK(selected.folderAlias));
          else appStore!.navigateTo(NAVIGATION.DASHBOARD_PAGE.LINK(activeHref));
        }
      }))
      .catch(e => this.props.appStore?.onError(e))
      .finally(() => {
        if (setIsLoadingDocuments) setIsLoadingDocuments(false);
      });
  }

  getRedirectUrl(document) {
    const isInTakeForm = ['EXCEPTION_REQUEST', 'EXTENSION_REQUEST', 'CLOSURE_REQUEST', 'INTAKE_FORM', 'EXTENSION_FORM', 'CLOSURE_FORM'].includes(document.documentType);
    const draftBadge = this.isDraftBadge(document);
    if (isInTakeForm) return NAVIGATION.INTAKE_FORM_PAGE.LINK(document.id, draftBadge);
    if (document.supplementalTag && document.supplementalTag.length && document.supplementalTag !== 'Global') return NAVIGATION.EXISTING_SUPPLEMENTAL_PAGE.LINK(document.id, document.supplementalTag);
    return NAVIGATION.DOCUMENT_PAGE.LINK(document.id, draftBadge);
  }


  renderFileHeader(document: any) {
    const { documentWrapper: { selectedFolder } } = this.props;
    const draftBadge = this.isDraftBadge(document);
    const isFavoritesFolder: boolean = selectedFolder?.id === 'FAVORITES';
    const isDocument = document.entityType === 'document';


    return (
      <Header
        className="policy--document-title-margin"
        actions={
          <SpaceBetween direction="horizontal" size="xxs">
            {document.isTemplate && <Badge color="grey" className="policy--document-badges">Template</Badge>}
            <Badge color="grey" className="policy--document-badges"> {document.documentType} </Badge>
            <Badge color="grey" className="policy--document-badges">{draftBadge}</Badge>
            {isFavoritesFolder && isDocument && <Badge color="grey" className="policy--document-badges">DOCUMENT</Badge>}
            {document.metaData?.underReview &&
              <Badge color="red" className="policy--document-badges">Under Review</Badge>
            }
            {document.metaData?.underBulkReview &&
              <Badge color="red" className="policy--document-badges">Under Bulk Review</Badge>
            }
            {!selectedFolder && document?.metaData?.parentFolderId && document?.metaData?.parentFolderName &&
              <Link
                variant="secondary"
                href={NAVIGATION.FOLDER_MANAGEMENT_PAGE.LINK(document?.metaData?.parentFolderId)}
                target="_blank" className="policy--document-link-icon" >
                <Badge color="grey" className="policy--document-badges">
                  <Icon name="folder-open" />&nbsp;&nbsp;{document?.metaData?.parentFolderName}
                </Badge>
              </Link>
            }
          </SpaceBetween>
        }
      >

        {document?.entityType === 'folder' ?
          // Used for rendering folders on the Favorites page since a redirect is not needed.
          <TextContent>
            <a onClick={() => this.handleFavoriteFolderClick(document)} className="policy--document-detail-title">
              {document.title}&nbsp;
            </a>
          </TextContent>
          :
          <SpaceBetween size="xxs">
            <Link
              variant="secondary"
              external
              href={this.getRedirectUrl(document)}
              target="_blank"
              className="policy--document-link-icon"
            >
              <span className="policy--document-detail-title">
                {document.title}&nbsp;
              </span>
            </Link>
          </SpaceBetween>
        }
      </Header>
    );
  }

  generateCorrectTitle(title, entityId) {
    if (title && entityId in title) {
      return title[entityId];
    }
    return 'No Title';
  }


  generateExternalUrl(item) {
    if (item.link?.includes('https://')) {
      return item.link;
    }
    return `https://${item.link}`;
  }

  DocumentSection = ({ items, displayedItems, sectionTitle, isInTakeForm, document, draftBadge, showAll, toggleShowAll }) => {
    const itemsToShow = showAll ? items : displayedItems;

    if (!items?.length) return <></>;
    return (
      <section>
        <ExpandableSection
          key={items.length}
          className="expandable-section-styles"
          header={<h5 className="expandable-section-styles-title">{sectionTitle} ({items.length})</h5>}
        >
          <section>
            <SpaceBetween size="s" direction="vertical">
              <section>
                <SpaceBetween size="s" direction="horizontal">
                  {itemsToShow.map((item, index) => (
                    <div
                      key={item.entityId}
                      className={`link-container ${index === itemsToShow.length - 1 ? '' : 'link-with-divider'}`}
                    >
                      {item.entityType === 'EXTERNAL' ? (
                        <div title={item.title}>
                          <Link external href={this.generateExternalUrl(item)}>
                            {item.title}
                          </Link>
                        </div>
                      ) : (
                        <Link href={isInTakeForm ? NAVIGATION.INTAKE_FORM_PAGE.LINK(item.entityId, draftBadge) : NAVIGATION.DOCUMENT_PAGE.LINK(item.entityId, draftBadge)}>
                          <div title={this.generateCorrectTitle(document.rsTitle, item.entityId)}>
                            {(() => {
                              const fullTitle = this.generateCorrectTitle(document.rsTitle, item.entityId);
                              return fullTitle;
                            })()}
                          </div>
                        </Link>
                      )}
                    </div>
                  ))}
                </SpaceBetween>
              </section>
              <section>
                {items.length > 20 && (
                  <div style={{ display: 'inline-block', width: '100%' }}>
                    <Button
                      href="#"
                      iconName="treeview-expand"
                      variant="link"
                      onClick={toggleShowAll}>
                      {showAll ? 'View Less' : 'View All'}
                    </Button>
                  </div>
                )}
              </section>
            </SpaceBetween>
          </section>
        </ExpandableSection>
      </section>
    );
  };

  @action.bound
  setSearchText(value) {
    this.searchText = value.toLowerCase();
  }

  @action.bound
  searchDocuments(folderId) {
    this.props.dbFolderContainerStore?.getFilesUnderFolder(folderId, 1, 10, this.searchText, this.documentTypesSelected);
  }


  renderDocumentDetail(document, isRelated) {
    const draftBadge = this.isDraftBadge(document);
    const isInTakeForm = ['EXCEPTION_REQUEST', 'EXTENSION_REQUEST', 'CLOSURE_REQUEST', 'INTAKE_FORM', 'EXTENSION_FORM', 'CLOSURE_FORM'].includes(document.documentType);
    let domainItems;
    let domainDisplayedItems;
    let relatedItems;
    let relatedDisplayedItems;
    if (document.rsMedata && typeof document.rsMedata === 'object') {
      domainItems = document.rsMedata.domain;
      domainDisplayedItems = domainItems?.slice(0, 20);
      relatedItems = document.rsMedata.related;
      relatedDisplayedItems = relatedItems?.slice(0, 20);
    }

    return (
      <Box key={`1 - ${document.id}`} className="policy--document-row-layout ">
        <Box key={`2 - ${document.id}`} className="policy--document-item-layout">
          <Box>
            {this.renderFileHeader(document)}
            <Box key={`3 - ${document.id}`}><span className="policy--document-attributes">{document.description}</span></Box>
            {document.documentType && isRelated ? (
              <>
                {this.DocumentSection({
                  items: domainItems,
                  displayedItems: domainDisplayedItems,
                  sectionTitle: 'Related/Domain Documents',
                  isInTakeForm,
                  document,
                  draftBadge,
                  showAll: this.state.showAll,
                  toggleShowAll: this.toggleShowAll,
                })}
                {this.DocumentSection({
                  items: relatedItems,
                  displayedItems: relatedDisplayedItems,
                  sectionTitle: 'Reference Documents',
                  isInTakeForm,
                  document,
                  draftBadge,
                  showAll: this.state.showAll,
                  toggleShowAll: this.toggleShowAll,
                })}
              </>
            ) : <></>}
          </Box>
        </Box>
      </Box>

    );

  }

  renderHeaderDescription(metaData: any): JSX.Element {
    let message;

    if (metaData?.description) {
      message = metaData.description;
    } else if (metaData?.isAdmin) {
      message = 'Click the Manage button to move, rename, or update permisions, approvals, and templates on this folder.';
    } else {
      message = 'Please click the View button to see this folder\'s permissions, approvals, and custom process templates.';
    }

    return (
      <span className="policy--folder-header-desc">
        {message}
      </span>
    );
  }

  renderCheckBox(folderId) {
    return (
      <section className="align-center">
        <Toggle
          onChange={action(({ detail }) => {
            this.props.dbFolderContainerStore?.setIncludeSubFolder(detail.checked);
            this.searchText ? this.props.dbFolderContainerStore?.getFilesUnderFolder(folderId, 1, 10, this.searchText) : this.props.dbFolderContainerStore?.getFilesUnderFolder(folderId);
          })}
          checked={this.props.dbFolderContainerStore?.includeSubFolders || false}
        >
          <span className="policy--document-checkbox">Show subfolders</span>
        </Toggle>
      </section>
    );
  }

  renderManageButton(metaData) {
    if (metaData?.isAdmin) {
      return (
        <Button iconAlign="left" iconName="unlocked" variant="primary" target="_blank" onClick={this.handleEditClick} >
          Manage
        </Button>
      );
    } else if (metaData?.canRead) {
      return (
        <Button iconAlign="left" iconName="lock-private" variant="normal" target="_blank" onClick={this.handleEditClick} >
          View
        </Button>
      );
    } else {
      return (
        <Button iconAlign="left" iconName="lock-private" variant="normal" target="_blank" onClick={this.handleEditClick} >
          Access Denied
        </Button>
      );
    }
  }

  renderManageFolderHelp(metaData) {
    if (metaData?.isAdmin) {
      return (
        <section className="align-center policy--icon-help-marigin">
          <Popover
            className="policy--permission-help-popover"
            position="bottom" size="medium" triggerType="custom" content={
              <div className="policy--dashboard-popover">
                Folder Settings
                <hr />
                <p className="policy--tool-tip-description">
                  Please click &quot;Manage&quot; button to move or rename this folder and update folder permisions,
                  approval and business process templates defined for this folder. Also
                  refer
                  <Link
                    className="policy--dashboard-popover-link"
                    href={EXTERNAL_LINKS.FOLDER_MANAGEMENT_HELP}
                    target="_blank"
                  >
                    user guide
                    <Icon name="external" />
                  </Link>
                  for more details.
                </p>
              </div>
            }>
            <InfoOutlined className="policy--icon-help align-center" />
          </Popover>
        </section>
      );
    } else {
      return (
        <section className="align-center policy--icon-help-marigin">
          <Popover
            className="policy--permission-help-popover"
            position="bottom" size="medium" triggerType="custom" content={
              <div className="policy--dashboard-popover">
                Folder Settings
                <hr />
                <p className="policy--tool-tip-description">
                  You have the view permission and please click &apos;View&apos; to find folder owners or
                  refer <Link className="policy--dashboard-popover-link" href={EXTERNAL_LINKS.FOLDER_MANAGEMENT_HELP} target="_blank">user guide <Icon name="external" /></Link> for more details.
                </p>
              </div>
            }>
            <InfoOutlined className="policy--icon-help align-center" />
          </Popover>
        </section>
      );
    }
  }

  renderSearchAndFilters(): any {
    const { documentWrapper: { selectedFolder } } = this.props;
    const isFavoritesFolder: boolean = selectedFolder?.id === 'FAVORITES';
    return (
      <Grid disableGutters gridDefinition={[{ colspan: 11 }, { colspan: 1 }]}>
        {!isFavoritesFolder &&
        <section>
          <Input
            type="search"
            placeholder="Search keywords or document titles in the current folder"
            value={this.searchText}
            onKeyDown={e => {
              if (e.detail.key === 'Enter') {
                this.searchDocuments(selectedFolder?.id);
              }
            }}
            onChange={detail => {
              this.setSearchText(detail?.detail?.value);
            }}
          />
        </section>
        }
        {!isFavoritesFolder &&
        <section className="policy--folder-search">
          <Button iconName="search" variant="primary" onClick={() => this.searchDocuments(selectedFolder?.id)} />
        </section>
        }
        {!isFavoritesFolder &&
        <section className="policy--folder-search-filters">
          <SpaceBetween direction="horizontal" size="xs">
            <Checkbox
              onChange={action(({ detail }) => {
                if (detail?.checked) {
                  this.documentTypesSelected = ['POLICY', 'PROCEDURE', 'STANDARD', 'GUIDELINE', 'TRANSCLUDE', 'OTHER'];
                } else {
                  this.documentTypesSelected = [];
                }
                this.filterAllCheckboxEnabled = detail?.checked;
                this.handleAllCheckboxClick(detail?.checked);
                this.searchDocuments(selectedFolder?.id);
              })}
              checked={this.filterAllCheckboxEnabled} >
              All
            </Checkbox>

            <Checkbox
              onChange={action(({ detail }) => {
                this.handleCheckboxChange(DocumentTypes.POLICY, detail?.checked, selectedFolder?.id);
                this.filterPolicyCheckboxEnabled = detail?.checked;
              })}
              checked={this.filterPolicyCheckboxEnabled} >
              Policies
            </Checkbox>

            <Checkbox
              onChange={action(({ detail }) => {
                this.handleCheckboxChange(DocumentTypes.STANDARD, detail?.checked, selectedFolder?.id);
                this.filterStandardCheckboxEnabled = detail?.checked;
              })}
              checked={this.filterStandardCheckboxEnabled} >
              Standards
            </Checkbox>

            <Checkbox
              onChange={action(({ detail }) => {
                this.handleCheckboxChange(DocumentTypes.PROCEDURE, detail?.checked, selectedFolder?.id);
                this.filterProcedureCheckboxEnabled = detail?.checked;
              })}
              checked={this.filterProcedureCheckboxEnabled} >
              Procedures
            </Checkbox>

            <Checkbox
              onChange={action(({ detail }) => {
                this.handleCheckboxChange(DocumentTypes.GUIDELINE, detail?.checked, selectedFolder?.id);
                this.filterGuidelineCheckboxEnabled = detail?.checked;
              })}
              checked={this.filterGuidelineCheckboxEnabled} >
              Guidelines
            </Checkbox>

            <Checkbox
              onChange={action(({ detail }) => {
                this.handleCheckboxChange(DocumentTypes.TRANSCLUDE, detail?.checked, selectedFolder?.id);
                this.filterTranscludeCheckboxEnabled = detail?.checked;
              })}
              checked={this.filterTranscludeCheckboxEnabled} >
              Transcludes
            </Checkbox>

            <Checkbox
              onChange={action(({ detail }) => {
                this.handleCheckboxChange(DocumentTypes.OTHER, detail?.checked, selectedFolder?.id);
                this.filterOtherCheckboxEnabled = detail?.checked;
              })}
              checked={this.filterOtherCheckboxEnabled} >
              Other
            </Checkbox>
          </SpaceBetween>
        </section>
        }
      </Grid>
    );
  }

  renderFolderHeader(): any {
    const { appStore, dbFolderContainerStore, favoritesStore, myOrganizationStore } = this.props;
    const totalItems: number = this.props.documentWrapper?.documentMetadata?.totalItems ?? null;
    const isFavoritesFolder: boolean = dbFolderContainerStore?.selectedFolder?.id === 'FAVORITES';

    return !dbFolderContainerStore?.selectedFolder ? (
      <TextContent><p>{totalItems} Items Found</p></TextContent>
    ) : (
      <Header
        variant="h3"
        description={this.renderHeaderDescription(dbFolderContainerStore?.selectedFolder?.metaData)}
        actions={
          <>
            {/* The Favorites folder should not have a Manage button. */}
            {!isFavoritesFolder && (
              <section id="policy--box-header-actions-section">
                <SpaceBetween direction="horizontal" size="xs">
                  {this.renderCheckBox(dbFolderContainerStore?.selectedFolder?.id)}
                  {this.renderManageFolderHelp(dbFolderContainerStore?.selectedFolder?.metaData)}
                  <section className="align-center">
                    <CopyButton icon="copy" type="Button" folderAlias={dbFolderContainerStore?.selectedFolder.folderAlias} />
                  </section>
                  {this.renderManageButton(dbFolderContainerStore?.selectedFolder?.metaData)}
                </SpaceBetween>
              </section>
            )}
          </>
        }
      >
        <Grid disableGutters gridDefinition={[{ colspan: 12 }, { colspan: 12 }]}>
          <TextContent>
            <SpaceBetween direction="vertical" size="xs">
              <section id="policy--box-header-section">
                <span className="policy--box-header">
                  <SpaceBetween direction="horizontal" size="xs">
                    <section style={{ display: 'flex', alignItems: 'center', gap: '0.5rem' }}>
                      <Icon className="policy--folder-dashboard-icon" id="folder-icon" name="folder-open" size="medium" />
                      <div>{dbFolderContainerStore?.selectedFolder?.folderName} ({(totalItems < 10000) ? totalItems : `${totalItems}+`})</div>
                    </section>
                    {/* Show the favorite icon next all folders except for the actual Favorites folder. */}
                    {appStore?.favoritesFeatureEnabled && !isFavoritesFolder && dbFolderContainerStore?.favoritesStore && (
                      <Suspense fallback={<></>}>
                        <Favorites store={favoritesStore} onClick={isFavorited => dbFolderContainerStore.handleFavoritesClick(isFavorited)} />
                      </Suspense>
                    )}
                    {myOrganizationStore && !isFavoritesFolder && (
                      <Suspense fallback={<></>}>
                        <MyOrganization store={myOrganizationStore} entityId={dbFolderContainerStore?.selectedFolder?.id} />
                      </Suspense>
                    )}
                  </SpaceBetween>
                </span>
              </section>
            </SpaceBetween>
          </TextContent>
        </Grid>
      </Header>
    );
  }

  render() {
    const { documentWrapper, appStore, isSearch, isLoading } = this.props;
    const isFavoritesFolder: boolean = documentWrapper.selectedFolder?.id === 'FAVORITES';

    // if the documents are loading, show spinner
    if (isLoading) {
      return <LoadingSpinner size="big" message="Loading documents..." absoluteCenter />;
    }

    // if there are no documents, then show no documents message
    if (!documentWrapper.documents) {
      return (
        <TextContent>
          <div className="policy--document-none">
            <br />
            <div className="policy--document-none-message">
              <div style={{ flex: 0.1, height: 1, backgroundColor: '#ff9900' }} />
              <Icon className="policy--document-icon-warning" id="folder-icon" name="folder-open" size="large" />
              <div style={{ flex: 0.1, height: 1, backgroundColor: '#ff9900' }} />
            </div>
            <p className="policy--document-none-message-next" >Please select a folder on the left to see its contents here. </p>
          </div>
        </TextContent>
      );
    }
    if (documentWrapper.documents.length === 0) {
      return (
        <>
          {this.renderFolderHeader()}
          {!isSearch ? this.renderSearchAndFilters() : null}
          <div className="policy--document-none">
            <br />
            <div className="policy--document-none-message">
              <div style={{ flex: 0.1, height: 1, backgroundColor: '#ff9900' }} />
              <Icon className="policy--document-icon-warning" id="folder-icon" name="folder-open" size="large" />
              <div style={{ flex: 0.1, height: 1, backgroundColor: '#ff9900' }} />
            </div>
            <p className="policy--document-none-message"> There is no information to display here because the folder you selected does not have any documents.</p>
          </div>
        </>
      );
    }

    // Return all document details
    return (

      <section style={{ height: '100%' }}>
        <SpaceBetween direction="vertical" size="xs">
          {this.renderFolderHeader()}
          {!isSearch ? this.renderSearchAndFilters() : null}
        </SpaceBetween>
        <section id="policy--document-pagination-documents-section" style={{ height: `${isSearch ? '65vh' : '72vh'}` }}>
          {documentWrapper?.documents.map(document => this.renderDocumentDetail(document, this.props.appStore?.isRelated))}
        </section>

        <section id="policy--document-pagination-controls-section">
          <Grid
            gridDefinition={[
              { colspan: 10 },
              { colspan: 2 },
            ]}
          >
            <SpaceBetween direction="vertical" size="xl">
              <span />
              <Pagination
                ariaLabels={{
                  nextPageLabel: 'Next page',
                  previousPageLabel: 'Previous page',
                  pageLabel: pageNumber =>
                    `Page ${pageNumber} of all pages`,
                }}
                currentPageIndex={appStore?.paginationPageNumber || 0}
                onChange={({ detail }) => {
                  appStore?.setCurrentPageIndex(detail.currentPageIndex);
                  if (isFavoritesFolder) {
                    this.props.dbFolderContainerStore?.setFavorites();
                    return;
                  }
                  isSearch ? this.runSearch() : this.getFolders();
                }}
                pagesCount={documentWrapper?.documentMetadata?.totalPages || 1}
              />
            </SpaceBetween>
            <section id="policy--document-pagination-amount-select-section">
              <TextContent>
                <small>Items Per Page</small>
              </TextContent>
              <Select
                id="policy--document-pagination-amount-select"
                selectedOption={appStore?.paginationPageSize || { label: '10', value: '10' }}
                onChange={({ detail }) => {
                  appStore?.setPaginationAmount(detail.selectedOption);
                  if (isFavoritesFolder) {
                    this.props.dbFolderContainerStore?.setFavorites();
                    return;
                  }
                  isSearch ? this.runSearch() : this.getFolders();
                }}
                options={[
                  { label: '5', value: '5' },
                  { label: '10', value: '10' },
                  { label: '25', value: '25' },
                ]}
                selectedAriaLabel="Select number of documents per page."
              />
            </section>
          </Grid>
        </section>
      </section>
    );
  }
}
