import React, { useState, MouseEvent, useEffect } from 'react';
import './myOrganizationStyles.scss';
import { MyOraganizationStore } from './myOrganizationStore';
import { BookmarkAdd, BookmarkAddedOutlined } from '@mui/icons-material';

interface MyOrganizationProps {
  store: MyOraganizationStore;
  entityId: string;
}

const MyOrganization = (props: MyOrganizationProps): JSX.Element => {
  const { store, entityId } = props;
  const [isCurrentMyOrg, setIsCurrentMyOrg] = useState(false);
  useEffect(() => {
    setIsCurrentMyOrg(store.isMyCurrentOrg(entityId));
  }, []);
  /**
   * Sets local favorited state and makes API call in store.
   * @param {MouseEvent} e Click event.
   */
  const handleOrgClick = (e: MouseEvent): void => {
    e.stopPropagation();
    store.onOrgClick(isCurrentMyOrg, entityId);
    setIsCurrentMyOrg(!isCurrentMyOrg);
  };

  return (
    <span id="icon-wrapper" className="icon-wrapper" title={isCurrentMyOrg ? 'Remove My Org' : 'Set My Org'} onClick={e => handleOrgClick(e)}>
      {isCurrentMyOrg ? <BookmarkAddedOutlined id="star-icon" /> : <BookmarkAdd id="star-border-icon" />}
    </span>
  );
};

export default MyOrganization;
