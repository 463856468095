/* eslint-disable max-lines */
import React, { Suspense, createRef, lazy } from 'react';
import { RouterStore } from 'mobx-react-router';
import { observable, action, computed } from 'mobx';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { Store } from './store';
import AppLayoutStore from './appLayoutStore';
import { AppSyncClient } from '../../api/auth/appSyncClient';
import { ErrorModel, isErrorModel } from 'components/error/errorModel';
import { AppToastAlertStore } from './toastAlert/appToastAlertStore';
import { FlyoverStore } from 'flyover/flyoverStore';
import DocSideNavStore from '../../pages/document/components/sideNav/docSideNavStore';
import { DocumentTypeModel } from 'api/document';
import { AppTopNavStore } from './topNav/appTopNavStore';
import { GROUP_TYPES } from 'constants/groupTypes';
import { BreadcrumbItem } from './breadcrumb/breadcrumb';
import { Folder } from 'api/folder';
import { getDocumentQuery, getFolderMetadata, getUserAllowedActions } from 'api/graphql/query';
import { OptionDefinition } from '@amzn/awsui-components-react/polaris/internal/components/option/interfaces';
import { Cookie } from 'cookiesStore/cookieStore';
import { Icon, TextContent } from '@amzn/awsui-components-react';
import './appStoreStyles.scss';
import { NAVIGATION, PAGES_WITH_SIDE_NAV } from 'constants/navigation';
import { ENTITY_TYPE } from 'constants/entityType';

const StarIcon = lazy(() => import('@mui/icons-material/Star'));

export enum AccessType {
  ADMIN = 'admin',
  WRITE = 'write,admin',
  READ = 'read,write,admin',
}

export enum ContentType {
  DEFAULT = 'default',
  FORM = 'form',
  TABLE = 'table',
  CARDS = 'cards',
  WIZARD = 'wizard',
  DASHBOARD = 'dashboard',
}

export type CurrentEnvironment = 'LOCAL' | 'ALPHA' | 'BETA' | 'GAMMA' | 'PROD' | 'UNKOWN';

export default class AppStore {
  @observable contentType = ContentType.DEFAULT;
  @observable stores = new Map<string, any>();
  @observable loading = false;
  @observable selectedFolder;
  @observable sectionIconClicked;
  @observable splitpanel = false;
  appLayoutStore = new AppLayoutStore();
  appToastAlertStore = new AppToastAlertStore();
  @observable appTopNavStore: AppTopNavStore;
  @observable docSideNavStore?: DocSideNavStore;
  flyoverStores: FlyoverStore[] = [];
  flyoverPortal: Element | undefined;
  @observable documentTypes: DocumentTypeModel[] = [];
  @observable templateTypes: DocumentTypeModel[] = [];
  @observable breadcrumbItems: BreadcrumbItem[] = [];
  @observable isSideNavOpen = true;
  @observable paginationPageNumber = 1;
  @observable paginationPageSize: OptionDefinition = { label: '10', value: '10' };
  @observable currentEnvironment: CurrentEnvironment = 'UNKOWN';
  @observable EXCEPTIONS_DISABLED = false;
  @observable hideSearchBar = false;
  @observable documentOwners: Set<string> = new Set();
  @observable currentDocumentMetadata: any = null;
  @observable toolsHide = true;
  @observable toolsOpen = false;
  @observable toolBarContent = <></>;
  @observable toolBarWidth = 0;
  @observable isFeedbackVisible = false;
  @observable favoritesFeatureEnabled = true;
  @observable fullScreenLayout = true;
  // @observable tutorialStore: any = createRef();
  @observable cookiesStore: any = createRef();
  @observable useDocumentLayout = true;
  @observable hideSideNav = true;
  @observable sideNavItems: any[] | 'LOADING' = [];
  @observable sideNavOnClick: any = href => href;
  @observable tutorialData: any = {};
  @observable advancedViewEnabled = false;
  @observable useStickyBreadcrumbs = false;
  @observable isRelated? = false;
  @observable splitPanelContent? = <></>;
  @observable splitPanelHeader = '';
  @observable actionItemsSplitpanel = false;
  @observable rootFolderId = '';
  @observable rootFolderName = '';
  @observable isDocAttributesFormValid = true;

  @observable userAllowedActions: any;
  navParams: any;

  constructor(public api: AppSyncClient, private routingStore: RouterStore, private session: CognitoUserSession) {
    this.handleSideNav();
    this.appTopNavStore = new AppTopNavStore(this);
    this.setCurrentEnvironment();

    // Runs whenever the browser back or forward buttons are used.
    window.addEventListener('popstate', action(() => {
      const isSearchPage = window.location.pathname?.includes('/search/');
      this.hideSearchBar = isSearchPage;
    }), false);

    this.setUseDocumentLayout();
    this.setHideSideNav();
    this.addAccessibilitySettings();
    // --- REMOVE this after some time ---
    // Remove cookie for the redesign toggle.
    if (this.cookiesStore?.current?.hasCookie(Cookie.USE_POLARIS_VR)) {
      this.cookiesStore?.current?.removeCookie(Cookie.USE_POLARIS_VR);
    }
  }

  /**
   * Determins if the side nav is displayed.
   */
  handleSideNav(): void {
    const path = window.location.pathname;
    const isDocPage = path?.includes('/docs');
    const hasSideNav = PAGES_WITH_SIDE_NAV.some(page => path?.includes(`/${page}`));

    if (isDocPage && hasSideNav) {
      this.setSideNav([], null, true);
    } else {
      this.setSideNav([], null, false);
    }
  }

  @action
  setSplitPanel(splitpanel: any): void {
    this.splitpanel = splitpanel;
  }

  @action
  setSplitPanelContent(splitPanelContent: any): void {
    this.splitPanelContent = splitPanelContent;
  }

  /**
   * Various Accessability optimizations.
   */
  addAccessibilitySettings(): void {
    setTimeout(() => {
      // Various Aria Labels.
      const navCloseButton = document.querySelector('button[class*="awsui_navigation-close_"]');
      const homeBreadcrumb = document.querySelector('svg[class*="MuiSvgIcon-root"]');
      const breadcrumbAnchors = document.querySelectorAll('a[class*="awsui_anchor_"]');
      const allPolarisButtons = document.querySelectorAll('[class*="awsui_button_"]');
      if (navCloseButton) navCloseButton.ariaLabel = 'Close Side Nav.';
      if (homeBreadcrumb) homeBreadcrumb.ariaLabel = 'Navigate to Home page.';
      if (breadcrumbAnchors.length) breadcrumbAnchors.forEach(anchor => { anchor.ariaLabel = 'Breadcrumb navigation item.'; });

      // Target toggle button in the top nav.
      const topNav = document.querySelector('#policy--topnav');
      const utilitiesSection = topNav?.querySelector('div[class*="awsui_utilities_"]');
      const toggleButton = utilitiesSection?.querySelector('span[class*="awsui_icon_"]');
      if (toggleButton?.ariaHidden) toggleButton.ariaHidden = 'false';

      // Set aria label for any button that is missing it.
      allPolarisButtons.forEach(button => {
        if (!button?.ariaLabel) button.ariaLabel = 'Button';
      });
    }, 2000);
  }

  @action
  handleGetUserActions(entityId?: string, entityTypeParam?: ENTITY_TYPE | string) {
    let entityType = entityTypeParam;
    if (entityId == null) {
      return {};
    }
    if (entityType == null) {
      if (entityId?.includes('-')) {
        entityType = ENTITY_TYPE.FOLDER;
      } else {
        entityType = ENTITY_TYPE.DOCUMENT;
      }
    }
    return this.api.query(getUserAllowedActions, { entityId, entityType })
      .then(action(response => {
        this.userAllowedActions = response.data?.getUserAllowedActions;
        this.userAllowedActions = JSON.parse(this.userAllowedActions);
        return this.userAllowedActions;
      }))
      .catch(e => this.onError(e));
  }

  /**
   * Determines if the document layout should be used.
   * @param {boolean} value Checks the URL, otherwise defaults to false.
   */
  @action
  setUseDocumentLayout(value = false): void {
    const path = window.location.pathname;
    this.useDocumentLayout = ((path?.includes('/docs/') || path?.includes('/intake/')) && !path?.includes('/intake/new')) || value;
    this.useStickyBreadcrumbs = (path?.includes(NAVIGATION.DOCUMENT_PAGE.STATUS.REVIEW) || path?.includes(NAVIGATION.DOCUMENT_PAGE.STATUS.TRANSCLUDE_BULK_REVIEW)) && !path?.includes('/intake/');

  }

  /**
   * Determines if the side nav should be used.
   * @param {boolean} value Checks the URL, otherwise defaults to false.
   */
  @action
  setHideSideNav(value = false): void {
    const path = window.location.pathname;
    this.hideSideNav = !path?.includes('/docs/') || value;
  }


  /**
   * Checks the cookie store after it mounts.
   */
  checkCookiesStore(): void {
    // Determines if the feedback component should be auto-opened.
    this.showFeedback(!this.cookiesStore?.current?.hasCookie(Cookie.FEEDBACK));

    // Determins if new feature tutorial is displayed.
    this.showNewFeatureTutorial();

    // Determins if side nav is displayed.
    if (this.cookiesStore?.current?.hasCookie(Cookie.SIDE_NAV_CLOSED)) this.handleIsSideNavOpen(false);

    // Determins if user is viewing the advanced view on the document page.
    if (this.cookiesStore?.current?.hasCookie(Cookie.ADVANCED_VIEW_ACTIVE)) this.setAdvancedView(true);

    // Determins if dark mode is active.
    const { classList } = document.body;
    if (this.cookiesStore?.current?.hasCookie(Cookie.DARK_MODE_ACTIVE) && !classList?.contains('awsui-polaris-dark-mode')) {
      classList?.add('awsui-polaris-dark-mode');
      classList?.add('darkMode');
    }
  }

  /**
   * Determins if new feature tutorial is displayed.
   */
  showNewFeatureTutorial(): void {
    // Don't show tutorial if feedback component is visible.
    if (this.isFeedbackVisible) return;

    // Show tutorials
    const tutorial = this.newFeatureAdvancedView();
    if (tutorial) this.setTutorialData(tutorial);
  }


  // Determines the current environment based on the hostname.
  @action
  setCurrentEnvironment(): void {
    const getEnv = () => {
      switch (window.location.hostname) {
        case 'localhost': return 'LOCAL';
        case 'alpha.policy.a2z.com': return 'ALPHA';
        case 'beta.policy.a2z.com': return 'BETA';
        case 'gamma.policy.a2z.com': return 'GAMMA';
        case 'policy.a2z.com': return 'PROD';
        default: return 'UNKOWN';
      }
    };
    this.currentEnvironment = getEnv();
  }

  createCacheKey = (prefix: string, params: any = {}): string => `${prefix}${JSON.stringify(params)}`;

  createStore<T extends Store>(key: string, Type?: (new (appStore: AppStore) => Store) | null, instance?): T {
    let store = this.stores.get(key);
    if (!store && Type) {
      store = new Type(this);
      this.stores.set(key, store);
      store.init();
    }

    if (instance) this.stores.set(key, instance);

    return store;
  }

  @action
  createAppTopNavStore() {
    this.appTopNavStore = new AppTopNavStore(this);
  }

  getStore<T extends Store>(key: string): T {
    return this.stores.get(key);
  }

  @action
  setFlyoverPortal(flyoverPortal: Element) {
    this.flyoverPortal = flyoverPortal;
  }

  @action
  load(): void {
    this.loading = true;
    this.documentTypes = [
      { id: 'POLICY', label: 'Policy' },
      { id: 'STANDARD', label: 'Standard' },
      { id: 'PROCEDURE', label: 'Procedure' },
      { id: 'GUIDELINE', label: 'Guideline' },
      { id: 'TRANSCLUDE', label: 'Transclude' },
      { id: 'OTHER', label: 'Other' },
    ];
    // Policy do not support transclude templates
    this.templateTypes = [
      { id: 'POLICY', label: 'Policy' },
      { id: 'STANDARD', label: 'Standard' },
      { id: 'PROCEDURE', label: 'Procedure' },
      { id: 'GUIDELINE', label: 'Guideline' },
      { id: 'OTHER', label: 'Other' },
    ];
    this.onLoad();
  }

  @action
  onLoad(): void {
    this.loading = false;
  }

  @action
  onError(e: ErrorModel): void {
    this.loading = false;
    this.appToastAlertStore.addToastAlert(e, 'error');
  }

  @action
  navigateTo(path: string, navParams: any = {}, hideSearchBar = false): void {
    this.routingStore.push(path);
    this.navParams = navParams;
    this.appToastAlertStore.removeAllItems();
    this.hideSearchBar = hideSearchBar;
    this.setUseDocumentLayout();
    this.setHideSideNav();
  }

  navigateBack(): void {
    this.routingStore.goBack();
    this.appToastAlertStore.removeAllItems();
    this.setUseDocumentLayout();
    this.setHideSideNav();
  }

  setSelectedFolder(selectedFolder) {
    this.selectedFolder = selectedFolder;
  }

  onSectionIconClick(iconClicked): void {
    this.sectionIconClicked = iconClicked;
    window.dispatchEvent(new Event('iconClick'));
  }

  @action
  setSideNavStore(store: DocSideNavStore | undefined) {
    this.docSideNavStore = store;
  }

  @computed
  get currentUserID() {
    return this.session?.getIdToken()?.payload['custom:uid'];
  }

  getDocumentTypeModel(documentType: string) {
    return this.documentTypes.find(type => type.id === documentType);
  }

  getGroupTypeModel(groupType: string) {
    return GROUP_TYPES.find(type => type.id === groupType);
  }

  @action
  setBreadcrumbItems(breadcrumbItems: BreadcrumbItem[]): void {
    this.breadcrumbItems = breadcrumbItems;
  }

  // Generate breadcrumb items for App Store given folder data.
  // Breadcrumbs can be manually cleared out by passing in an empty array for folderPath.
  createBreadcrumbsFromFolderArray(folderPath: Folder[] = []): void {
    const breadcrumbItems: BreadcrumbItem[] = [];
    if (folderPath.length) {
      folderPath.forEach((folder, i1) => {
        let idPath = '';

        // Generate URL for each breadcrumb.
        for (let i2 = i1; i2 >= 0; i2--) {
          idPath = `${folderPath[i2].id}/${idPath}`;
        }

        // Add breadcrumb to array.
        // If accessed from org view or is on document page, breadcrumbs redirect to org view
        // otherwise (accessing management page from landing page), breadcrumbs redirect to landing page
        breadcrumbItems.push({
          text: folder.folderName,
          href: (this.rootFolderId.length > 0 || this.useDocumentLayout) ? `/folder/${idPath}` : `/#folderId=${idPath}`,
        });
      });

      // Add Home breadcrumb. This is converted to an icon in the Breadcrumb component.
      breadcrumbItems.unshift({
        type: 'ICON',
        text: 'ICON_HOME',
        href: '/',
      });
    }

    // Update App Store with breadcrumbs.
    this.setBreadcrumbItems(breadcrumbItems);
  }

  @observable rootFolder: any = {};
  @action
  getRootFolder(rootId: string) {
    let parentId = rootId;
    const getFolder = folderId => (
      this.api.query(getFolderMetadata, { folderId })
        .then(action(folder => {
          const folderData = folder?.data?.getFolderMetadata;
          this.isRelated = folderData.isRelated;
          if (folderData.parentId.length > 0) {
            // eslint-disable-next-line
            parentId = folderData.parentId;
            return getFolder(folderData.parentId);
          } else {
            return { ...folder, id: parentId };
          }
        }))
        .catch(() => {
          const msg = { message: 'Unable to get the folder metadata' };
          this.appToastAlertStore.addToastAlert(msg, 'error', true, {});
        })
    );
    return getFolder(rootId);
  }

  // Given the current ID of either a folder or document, recursively create a folder path with parents.
  // Breadcrumbs can be manually cleared out by passing in an empty string for currentFolderId.
  @action
  getDataForBreadcrumbs(currentFolderId: string, idType: 'FOLDER' | 'DOCUMENT' | 'PROCESS', location?: string, createBreadcrumbs = true, metadata?: any) {
    const folders: Folder[] = [];

    const setBreadcrumbs = (folderId: string): Folder[] => {
      // Add trailing breadcrumb if the user is on the Manage Folder page or the Process Management page.
      const addAdditionalCrumb = () => {
        const defaultData = { id: folderId, subFolders: [], createdBy: '', createdOn: 0 };
        switch (location) {
          case 'FOLDER_MANAGEMENT': return folders.push({ folderName: 'Settings', ...defaultData });
          case 'PROCESS_MANAGEMENT': return folders.push({ folderName: 'Process Builder', ...defaultData });
        }
      };
      // Create Breadcrumbs once root folder is found.
      addAdditionalCrumb();
      if (createBreadcrumbs) this.createBreadcrumbsFromFolderArray(folders);
      return folders;
    };

    const handleFolderMetadata = (folder: any, folderId: string) => {
      if (folder?.data?.getFolderMetadata) {
        const data = folder?.data?.getFolderMetadata;
        for (const item of JSON.parse(data.parentFolders)) {
          folders.push(new Folder({
            id: item.id,
            folderName: item.folderName,
            subFolders: [],
          }));
        }
        folders.push(new Folder({ id: data.id, folderName: data.folderName }));
        return setBreadcrumbs(folderId);
      }
    };

    // Get parent folder by folder ID.
    const getFolder = (folderId: string) => {
      if (!folderId || folderId.length === 0) return;
      return this.api.query(getFolderMetadata, { folderId })
        .then(folder => handleFolderMetadata(folder, folderId))
        .catch(e => this.onError(e));
    };

    // Get parent folders by document ID.
    const getDocument = (documentId: string): Promise<Folder[] | void> => {
      const instanceType = 'draft';
      return this.api.query(getDocumentQuery, { documentId, instanceType })
        .then(action(document => {
          this.currentDocumentMetadata = JSON.parse(document.data.getDocument.metaData);
          this.setParentFolderArrayAndCreateBreadCrumbs(document.data.getDocument);
        }))
        .catch(e => this.onError(e));
    };

    // The query depends on whether a Document ID or Folder ID is passed in.
    // An ID can only be from a document for the first call in the recursive stack.
    if (currentFolderId.length === 0) return this.createBreadcrumbsFromFolderArray([]);

    // Metadata can directly be passed in to avoid a duplicate call to getFolderMetadata.
    if (metadata) return handleFolderMetadata(metadata, currentFolderId);

    return idType === 'FOLDER' ? getFolder(currentFolderId) : getDocument(currentFolderId);
  }

  // Sets folder array and creates breadcrumbs.
  // This method helps avoid needing to make duplicate API calls to get breadcrumb data.
  setParentFolderArrayAndCreateBreadCrumbs(docData: any): Folder[] {
    const parentFolders = JSON.parse(docData?.metaData)?.parentFolders;
    let folders: Folder[] = [];

    if (parentFolders) {
      folders = parentFolders.map(({ id, folderName }) => ({ id, folderName }));
      folders.push({ id: docData.id, folderName: docData.title, ...docData });
      this.createBreadcrumbsFromFolderArray(folders);
    }
    return folders;
  }

  getErrorModel(e, entityType?: string) {
    let errorModel: any = null;
    if (e?.errors) {
      const [error] = e?.errors;
      if (error?.errorType === '401' || error?.errorType === '403') {
        errorModel = {
          statusCode: e?.errors?.[0].errorType,
          message: `User ${this.currentUserID} is not authorized to access/view this ${entityType}.`,
        };
      } else if (error?.errorType === '404') {
        errorModel = {
          statusCode: e?.errors?.[0].errorType,
          message: `${entityType} not found.`,
        };
      } else if (Object.prototype.hasOwnProperty.call(e, 'errors') && Array.isArray(e.errors)) {
        errorModel = error;
      }
    } else if (isErrorModel(e)) {
      errorModel = e;
    } else if (e?.errorType?.length && e?.message?.length) {
      errorModel = { statusCode: e.errorType, message: e.message };
    }

    if (!errorModel) {
      errorModel = {
        statusCode: '500',
        message: 'An unknown error occured. Please help us out by reporting a bug',
      };
    }
    return errorModel;
  }

  // Handles opening and closing the global Polaris side nav.
  // This is done manually in order to default the side nav to closed.
  @action
  handleIsSideNavOpen(isOpen: boolean): void {
    const cookie = Cookie.SIDE_NAV_CLOSED;
    this.isSideNavOpen = isOpen;

    if (isOpen && this.cookiesStore?.current?.hasCookie(cookie)) {
      this.cookiesStore?.current?.removeCookie(cookie);
    } else if (!isOpen) {
      this.cookiesStore?.current?.setCookie(cookie);
    }
    this.setSideNavIds();
  }

  // Sets the current page number in pagination controls.
  @action
  setCurrentPageIndex(index: number): void {
    this.paginationPageNumber = index;
  }

  // Sets the number of items displayed per pagination page.
  // Resets to pagination page 1.
  @action
  setPaginationAmount(itemsPerPage: OptionDefinition): void {
    this.setCurrentPageIndex(1);
    this.paginationPageSize = itemsPerPage;
  }

  /**
   * Sets the documentOwners property.
   * @param {{ users: { userAlias: string }[] }[]} documentOwners A list of owners for the current document.
   */
  @action
  setDocumentOwners(documentOwners: { users: { userAlias: string }[] }[]): void {
    if (!documentOwners.length) return;
    const userAliases: string[] = [];

    for (const roleGroup of documentOwners) {
      if (!roleGroup?.users?.length) continue;

      roleGroup?.users?.forEach(user => {
        userAliases.push(user?.userAlias);
      });
    }

    this.documentOwners = new Set(userAliases);
  }

  /**
   * Sets a new value for the toolsHide property. Defaults to toggle if no value passed in.
   * @param {boolean | null} value The new value for the toolsHide property.
   */
  @action
  setToolsHide(value: boolean | null = null): void {
    this.toolsHide = value === null ? !this.toolsHide : value;
  }

  /**
   * Sets a new value for the toolsOpen property. Defaults to toggle if no value passed in.
   * @param {boolean | null} value The new value for the toolsOpen property.
   */
  @action
  setToolsOpen(value: boolean | null = null): void {
    this.toolsOpen = value === null ? !this.toolsOpen : value;
  }

  /**
   * Sets a new value for the toolBarContent property.
   * @param {JSX.Element} content The new value for the toolBarContent property.
   */
  @action
  setToolBarContent(content: any | null = null): void {
    this.toolBarContent = content === null ? <></> : content;
  }

  /**
   * Sets the width of the toolbar.
   * @param {number | null} width The new width value.
   */
  @action
  setToolBarWidth(width: number | null = null): void {
    this.toolBarWidth = width === null ? 0 : width;
  }

  /**
   * Sets the visibility of the feedback button.
   * @param {boolean} isVisible Visible or not.
   * @param {boolean} setCookie Determines if a cookie should be set to prevent future auto-opens.
   */
  @action
  showFeedback(isVisible = true, setCookie = false): void {
    this.isFeedbackVisible = isVisible;
    if (!isVisible && setCookie) this.cookiesStore?.current?.setCookie(Cookie.FEEDBACK);
  }

  /**
   * Generates an alert for the user.
   * @param {string} message Message displayed in the alert (Defaults to "Unknown Error. Please try again later."").
   * @param {string} status Alert type (Defaults to "error").
   * @param {string} statusCode Alert code (Defaults to "400").
   */
  createToastAlert(message = 'Unknown Error. Please try again later.', status = 'error', statusCode = '400'): void {
    this.appToastAlertStore.addToastAlert({
      message,
      statusCode,
    }, status);
  }

  /**
   * Sets the contentType property for the App Layout component.
   * @param {ContentType} type New type value.
   */
  @action
  setContentType(type: ContentType = ContentType.DEFAULT) {
    this.contentType = type;
  }

  /**
   * Toggles the Polaris 3.0 refresh settings.
   */
  togglePolarisRefresh(): void {
    const classList = document.querySelector('body')?.classList;
    const refresh = 'awsui-visual-refresh';

    if (classList) {
      classList.contains(refresh) ? classList.remove(refresh) : classList.add(refresh);
    }
  }

  /**
   * Sets properties for the sidenav component.
   * @param {any[]} items Items to show in the side nav.
   * @param {any} onClick Method that runs when a side nav item is clicked.
   * @param {boolean} isLoading Is sidenav loading.
   */
  @action
  setSideNav(items: any[], onClick: any, isLoading = false): void {
    this.sideNavItems = isLoading ? 'LOADING' : items;
    this.sideNavOnClick = onClick;
    this.setSideNavIds();
  }

  /**
   * Add IDs to the nav button so automated systems can target/toggle.
   */
  setSideNavIds(): void {
    // Button when menu is open.
    const sideNavButtonOpen = document.querySelector('button[class*="awsui_navigation-close_"]');
    if (sideNavButtonOpen) sideNavButtonOpen.id = 'sideNavButton';

    // Button when menu is closed.
    const sideNavButtonClosed = document.querySelector('nav>[aria-expanded="false"]');
    if (sideNavButtonClosed) sideNavButtonClosed.id = 'sideNavButtonClosed';
  }

  /**
   * Sets the tutorialData property.
   * @param {any} tasks Data for AnnotationContext.
   */
  @action
  setTutorialData(tasks: any): void {
    this.tutorialData = tasks ?? {};
  }

  /**
   * Shows tutorial for the Advanced View toggle button.
   * @returns {any} Data for AnnotationContext.
   */
  newFeatureAdvancedView(): any {
    const path = window.location.pathname;
    const isDocPage: boolean = path.includes('/docs/') && !path.includes('/review');
    const cookie: Cookie[] = isDocPage ? [Cookie.NEW_FEATURE__MENU, Cookie.NEW_FEATURE__DOCPAGE_REDESIGN] : [Cookie.NEW_FEATURE__MENU];
    const steps: any[] = [];

    if (!this.cookiesStore?.current?.hasCookie(Cookie.NEW_FEATURE__MENU)) {
      steps.push({
        title: 'Menu',
        content: 'This menu has a link to the My Dashboard page and other actions.',
        hotspotId: 'menu',
      });
    }

    if (isDocPage && !this.cookiesStore?.current?.hasCookie(Cookie.NEW_FEATURE__DOCPAGE_REDESIGN)) {
      steps.push({
        title: 'Document Actions',
        content: (
          <TextContent>
            <p className="tutorial-document-actions">
              <Suspense fallback={<></>}>
                <StarIcon />
              </Suspense>
              Add to Favorites on homepage.
            </p>
            <p className="tutorial-document-actions">
              <Icon name="notification" className="padding" /> Receive email updates.
            </p>
            <p className="tutorial-document-actions">
              <Icon name="contact" className="padding" /> Add a public comment.
            </p>
            <p className="tutorial-document-actions">
              <Icon name="settings" className="padding" /> Group subscriptions, Advanced View mode, and other settings.
            </p>
          </TextContent>
        ),
        hotspotId: 'documentActions',
      });
      steps.push({
        title: 'Advanced View',
        content: 'The Advanced View shows extra info like Tags and the Version History.',
        hotspotId: 'advancedView',
      });
    }

    if (!steps.length) return null;
    return {
      title: '',
      description: '',
      completedScreenDescription: '',
      completed: false,
      tasks: [
        {
          title: 'New Features!',
          steps,
        },
      ],
      cookie,
    };
  }

  @action
  setAdvancedView(isEnabled: boolean): void {
    this.advancedViewEnabled = isEnabled;
    const cookie = Cookie.ADVANCED_VIEW_ACTIVE;
    isEnabled ? this.cookiesStore.current.setCookie(cookie) : this.cookiesStore.current.removeCookie(cookie);
  }

  @action
  setRootFolderId(rootFolderId: string): void {
    this.rootFolderId = rootFolderId;
  }

  @action
  setRootFolderName(rootFolderName: string): void {
    this.rootFolderName = rootFolderName;
  }

  @action
  setIsDocAttributesFormValid(isDocAttributesFormValid: boolean): void {
    this.isDocAttributesFormValid = isDocAttributesFormValid;
  }
}
