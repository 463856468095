import * as React from 'react';
import { observer } from 'mobx-react';
import { action } from 'mobx';
import { Box, Button, Icon, Link, Modal as PolarisModal, SpaceBetween } from '@amzn/awsui-components-react';
import { ModalActionType, ModalStore } from './modalStore';
import classNames from 'classnames';
import { EXTERNAL_LINKS } from 'constants/ExternalLinks';

interface Props {
  modalStore: ModalStore;
  customClassName?: string;
  primaryButtonLabel?: string;
  secondaryButtonLabel?: string;
  primaryButtonDisabled?: boolean;
  hideFooter?: boolean;
  onPrimary?(): void;
  onCancel?(): void;
  onDismiss?(): void;
  size?: 'small' | 'medium' | 'large' | 'max' | undefined;
  pages?: any[];
  hideSecondaryButton?: boolean;
  hidePrimaryButton?: boolean;
  children: any;
  persistOnPrimaryError?: boolean;
  helpLink?: string;
}

@observer
export default class Modal extends React.Component<Props, unknown> {
  static defaultProps = {
    primaryButtonLabel: 'OK',
    secondaryButtonLabel: 'Cancel',
    primaryButtonDisabled: false,
    hideSecondaryButton: false,
    persistOnPrimaryError: false,
  };

  @action.bound
  async handleAction(actionType: ModalActionType, isLastPage) {
    const { modalStore, onPrimary, onCancel, pages, persistOnPrimaryError } = this.props;
    modalStore.setLoading(true);
    if (actionType === ModalActionType.PRIMARY && onPrimary) {

      // If using pages and not on last page, increment page. Otherwise, execute primary action.
      if (pages && !isLastPage) {
        modalStore.setActivePageIndex(modalStore.activePageIndex + 1);
        modalStore.setLoading(false);
        return;
      }

      try {
        await onPrimary();
        modalStore.setLoading(false);
        modalStore.performAction(actionType);
      } catch {
        modalStore.setLoading(false);
        modalStore.performAction(actionType, persistOnPrimaryError);
      }
    } else if (actionType === ModalActionType.CANCEL && onCancel) {
      try {
        await onCancel();
        modalStore.setLoading(false);
        modalStore.performAction(actionType);
      } catch {
        modalStore.setLoading(false);
        modalStore.performAction(actionType);
      }
    } else {
      modalStore.setLoading(false);
      modalStore.performAction(actionType);
    }
  }

  /**
   * Renders a given modal page, if the pages feature is being used.
   * @returns {JSX.Element} Returns page content.
   */
  renderPageContent(): any {
    const { modalStore, pages } = this.props;
    const activePage = pages ? pages[modalStore.activePageIndex] : <></>;
    return <section> {activePage} </section>;
  }

  render() {
    const {
      children, modalStore, customClassName, primaryButtonLabel, secondaryButtonLabel, primaryButtonDisabled,
      size, hideFooter, pages, hideSecondaryButton, hidePrimaryButton, onDismiss,
    } = this.props;
    const { isVisible, header } = modalStore;
    const isLastPage = pages && modalStore.activePageIndex === pages.length - 1;
    const classnames = classNames(
      'policy--modal',
      customClassName,
    );
    const headerWithInfo = <>{`${header} `}
      <Link fontSize="body-s" href={this.props.helpLink ?? EXTERNAL_LINKS.DOCUMENT_MANAGEMENT_HELP} target="_blank">
        <Icon
          name="status-info"
          size="small"
          variant="link"
        />
      </Link>
    </>;

    return (
      <section>
        <PolarisModal
          className={classnames}
          onDismiss={() => (onDismiss ? onDismiss() : modalStore.performAction(ModalActionType.CANCEL))}
          visible={isVisible}
          header={headerWithInfo}
          footer={!hideFooter && (
            <Box float="right">
              <SpaceBetween direction="horizontal" size="xs">
                {!hideSecondaryButton &&
                  <Button className="policy--modal-secondary-button" variant="link" onClick={() => this.handleAction(ModalActionType.CANCEL, isLastPage)}>
                    {secondaryButtonLabel}
                  </Button>
                }
                {
                  pages && pages.length > 0 && modalStore.activePageIndex !== 0 &&
                  <Button className="policy--modal-secondary-button" onClick={() => modalStore.setActivePageIndex(modalStore.activePageIndex - 1)} loading={modalStore.loading}>
                    Previous
                  </Button>
                }
                {!hidePrimaryButton && <Button className="policy--modal-primary-button" disabled={primaryButtonDisabled} variant="primary" onClick={() => this.handleAction(ModalActionType.PRIMARY, isLastPage)} loading={modalStore.loading}>
                  {pages && !isLastPage ? 'Next' : primaryButtonLabel}
                </Button>
                }
              </SpaceBetween>
            </Box>
          )
          }
          size={size}
        >
          {pages && pages.length ? this.renderPageContent() : children
          }
        </PolarisModal >
      </section >
    );
  }
}
