import { Store } from 'container/app/store';
import { action, observable } from 'mobx';
import AppStore from 'container/app/appStore';
import { AddFolderModalStore } from '../../../pages/dashboard/components/dbFolderContainer/folderModals/addFolderModalStore';
import { createDocument, createFolder } from 'api/graphql/mutation';
import { ErrorModel } from 'components/error/errorModel';
import { NAVIGATION } from 'constants/navigation';
import { CreateDocumentModalStore } from '../../../pages/dashboard/createDocumentModalStore';
import { PreferenceModalStore } from 'pages/dashboard/preferenceModalStore';
import { createPreferences } from 'api/graphql/mutation';
import { getPreferences } from 'api/graphql/query';

export class AppTopNavStore implements Store {
  @observable appStore: AppStore;
  @observable folderModalStore!: AddFolderModalStore;
  @observable documentModalStore!: CreateDocumentModalStore;
  @observable preferenceModalStore!: PreferenceModalStore;
  @observable organizationUrl = '';
  @observable showPreference = false;
  constructor(appStore: AppStore) {
    this.appStore = appStore;
  }

  // eslint-disable-next-line no-empty-function,@typescript-eslint/no-empty-function
  init(): void {
  }

  @action.bound
  handleCreateClick(item) {
    const selectedId = item.detail.id;
    const [, documentType] = selectedId.split('-');

    switch (selectedId) {
      case 'folder':
        this.openCreateFolderModal();
        break;
      default:
        this.openCreateDocumentModal(documentType, !selectedId.startsWith('document-'));
    }
  }

  @action
  openCreateFolderModal() {
    this.folderModalStore = new AddFolderModalStore('Create folder', this.appStore);
    this.folderModalStore.init();
    this.folderModalStore.setVisible(true);
  }

  @action
  openCreateDocumentModal(documentType: string, templateIdentifier: boolean) {
    const documentTypeModel = this.appStore.getDocumentTypeModel(documentType);
    const header = templateIdentifier ? `Create ${documentTypeModel?.label} template` : `Create ${documentTypeModel?.label}`;
    this.documentModalStore = new CreateDocumentModalStore(header, this.appStore, documentType, templateIdentifier);
    this.documentModalStore.init();
    this.documentModalStore.setVisible(true);
  }

  @action
  async openPreferenceModal() {
    await this.appStore.api.query(getPreferences, {})
      .then(action(({ data }) => {
        const preferences = JSON.parse(data.getPreferences);
        this.organizationUrl = preferences?.organizationUrl;
        this.preferenceModalStore = new PreferenceModalStore(this.appStore, this.organizationUrl);
        this.preferenceModalStore.init();
        this.preferenceModalStore.setVisible(true);
      }));
  }

  @action
  isValidFolderAlias(input: string): boolean {
    const regex = /^[a-zA-Z_]+$/;
    return regex.test(input);
  }

  @action.bound
  async handleCreateFolder() {
    const model = this.folderModalStore.formToModel();
    let folderAliasIsValid = true;

    // Validate folderAlias
    if (model?.folderAlias !== undefined) {
      if (model.folderAlias.length >= 1) {
        if (!this.isValidFolderAlias(model.folderAlias)) {
          folderAliasIsValid = false;
        }
      }
    }

    const params = {
      folderName: model.folderName,
      parentId: model.parentId ? model.parentId.value : '',
      clonePermission: model.clonePermission ? model.clonePermission : false,
      isPublic: true,
      footerContent: model.footerContent,
      folderAlias: model.folderAlias,
    };

    if (!folderAliasIsValid) {
      return this.onFolderAliasFail();
    }

    return this.appStore.api.mutate(createFolder, params)
      // setTimeout is being used here because looks like when create returns the data might not be synced to ES
      // so we let it wait for 1 second and then call get metadata
      .then(response => setTimeout(() => this.onFolderCreateComplete(response), 1000))
      .catch(e => {
        this.onFolderCreateFail(e.errors[0]);
      });
  }

  @action
  onFolderCreateComplete(response) {
    const { data: { createFolder: { id } } } = response;
    this.appStore.navigateTo(NAVIGATION.FOLDER_MANAGEMENT_PAGE.LINK(id));
  }

  @action.bound
  onFolderAliasFail() {
    this.appStore?.appToastAlertStore.addToastAlert(
      {
        message: 'Pretty URL can only contain letters and underscores.',
      },
      'error'
    );
  }

  @action.bound
  onFolderAliasDuplicate() {
    this.appStore?.appToastAlertStore.addToastAlert(
      {
        message: 'Pretty URL has already been taken',
        statusCode: '400',
      },
      'error'
    );
  }

  @action
  onFolderCreateFail(error) {
    if (error.errorType === '401') {
      this.appStore?.appToastAlertStore.addToastAlert(
        {
          message: JSON.parse(error.message).message,
          statusCode: '401',
        },
        'error'
      );
    } else {
      this.appStore?.appToastAlertStore.addToastAlert(
        {
          message: 'Pretty URL has already been taken',
          statusCode: '400',
        },
        'error'
      );
    }
    // this.appStore.navigateTo(NAVIGATION.FOLDER_MANAGEMENT_PAGE.LINK(id));
  }

  @action
  onSearch(queryString) {
    this.appStore.navigateTo(NAVIGATION.SIMPLE_SEARCH_PAGE.LINK(queryString, this.appStore.rootFolderId), {}, true);
  }

  @action.bound
  handleCreateFolderCancelClick() {
    this.folderModalStore.setVisible(false);
  }

  @action.bound
  async handleCreateDocument() {
    const model = this.documentModalStore.formToModel();
    const params = {
      title: model.title,
      description: model.description ? model.description : '',
      documentType: model.documentType,
      isTemplate: model.isTemplate,
      parentFolderId: model.parentFolderId.value,
      parentTemplateId: model.parentTemplateId ? model.parentTemplateId.value : undefined,
      clonePermission: model.clonePermission,
      isPublic: model.isPublic ?? false,
      isShared: model.isShared ?? false,
    };
    return this.appStore!.api.mutate(createDocument, params)
      .then(value => this.onDocumentCreateComplete(value))
      .catch(e => this.onError(e.errors[0]));
  }

  @action.bound
  async handleSavePreference() {
    const model = this.preferenceModalStore.formToModel();
    const params = {
      organizationUrl: model.organizationUrl,
    };
    return this.appStore!.api.mutate(createPreferences, params)
      .then(value => this.onDocumentCreateComplete(value))
      .catch(e => this.onError(e.errors[0]));
  }

  onDocumentCreateComplete(value) {
    const { data: { createDocument: { id } } } = value;
    // Adding permissions to new documents might not be available immediately in auth service (due to replication),
    // therefor a delay of 3 second is introduced to mitigate access denied issue
    setTimeout(() => this.appStore.navigateTo(NAVIGATION.DOCUMENT_PAGE.LINK(id, 'draft')), 3000);
  }

  @action.bound
  handleCreateDocumentCancelClick() {
    this.documentModalStore.setVisible(false);
  }

  @action.bound
  handlePreferenceCancelClick() {
    this.preferenceModalStore.setVisible(false);
  }

  @action.bound
  handlePreferenceClickSuccess() {
    this.appStore.appToastAlertStore.addToastAlert({
      statusCode: '200',
      message: 'Preferenced Saved',
    }, 'success');
  }

  @action.bound
  onError(e: ErrorModel) {
    this.appStore.appToastAlertStore.addToastAlert(e, 'error');
  }

  /**
   * Handles clicks in the topnav menu.
   * @param {string} menuOption The ID from the menu item that was clicked.
   */
  @action
  handleMenuClick(menuOption: string): void {

    switch (menuOption) {
      case 'prefrence':
        this.openPreferenceModal();
        this.showPreference = true;
        break;
      default: null;
    }
  }
}

export const CACHE_PREFIX = 'TopNav-';
