/* eslint-disable dot-notation */
/* eslint-disable no-underscore-dangle */
/* eslint-disable max-lines */
import { EntityMetadata } from 'api/document';
import { Folder } from 'api/folder';
import { queryDocuments } from 'api/graphql/mutation';
import { getFolderMetadata, getFolders } from 'api/graphql/query';
import { ErrorModel } from 'components/error/errorModel';
import { AllFavoriteMetadata, FavoriteMetadata, FavoritesStore } from 'components/favorites/favoritesStore';
import { MyOraganizationStore } from 'components/myOrganization/myOrganizationStore';
import AppStore from 'container/app/appStore';
import { Store } from 'container/app/store';
import { action, observable } from 'mobx';
import { DocumentWrapper } from './documentWrapper';
import { NAVIGATION } from 'constants/navigation';
// Determines if any folders in the tree are expanded or selected by default.
// Used by the breadcrumb for navigation.
export interface TreeViewDefaults {
  defaultExpanded: string[];
  defaultSelected: string[] | string;
}

export class DbFolderContainerStore implements Store {
  @observable appStore: AppStore;
  @observable loading = true;
  @observable folders: Folder[] = [];
  @observable selectedFolder!: Folder | undefined;
  @observable documentWrapper = new DocumentWrapper(false, null, this.selectedFolder);
  @observable favoritesStore: any;
  @observable myOraganizationStore: any;

  @observable loadingDocuments = false;
  @observable includeSubFolders = false; // Disable show subfolders by default
  @observable searchText = '';
  @observable expandedMap = new Map();
  @observable expandedNodes: string[] = [];
  @observable treeViewDefaults: TreeViewDefaults = {
    defaultExpanded: [],
    defaultSelected: [],
  };
  @observable allFavoriteMetadata: AllFavoriteMetadata = [
    // FAVORITES_TEST_DATA
    // { entityId: '504261', entityType: 'document', title: 'Policy Document 1', description: 'This is the desc...' },
    // { entityId: '34c2c1ec-38ac-96c4-e51a-9f9291dc8cdf', entityType: 'folder', title: 'Test', description: 'This is the desc...' },
    /* eslint-disable-next-line */
    // { entityId: '8ec1f5fc-fb70-c318-c707-7b9f1452d114/34c2c1ec-38ac-96c4-e51a-9f9291dc8cdf/12c2ce7b-8164-4fe6-4249-0fc8a4118b2f/', entityType: 'folder', title: 'Praveen - Test Folder', description: 'This is the desc...' },
  ];

  init() {
    return {};
  }

  constructor(appStore: AppStore) {
    this.appStore = appStore;
  }

  @action
  load() {
    this.favoritesStore = new FavoritesStore(this.appStore, '', 'folder');
    this.myOraganizationStore = new MyOraganizationStore(this.appStore);
    this.myOraganizationStore.init();
    return this.appStore.api.query(getFolders, { loadAll: true, accessTypes: 'read,write,admin' })
      .then(value => this.onLoad(value))
      .catch(action(() => {
        this.appStore.createToastAlert('Failed to get folder data! Please try again later.');
        this.loading = false;
      }));
  }

  @action
  setSearchText(text) {
    this.searchText = text;
  }

  @action
  setIncludeSubFolder(value: boolean) {
    this.includeSubFolders = value;
  }

  @action
  onLoad(documents: any) {
    if (documents) {
      this.folders = documents.data.getFolders.map(folder => new Folder(folder));
      const landingPageUrlHasFolderId = (window.location.href.includes('#folderId=') && window.location.href.split('#folderId=')[1].length > 0);
      const orgViewUrlHasFolderId = (window.location.href.includes('folder/') && window.location.href.split('folder/')[1].length > 0);
      const orgViewUrlHasFolderAlias = (window.location.href.includes('org/') && window.location.href.split('org/')[1].length > 0);
      this.getTreeViewDefaults(landingPageUrlHasFolderId, orgViewUrlHasFolderId);

      if (this.appStore.favoritesFeatureEnabled && this.favoritesStore) {
        this.loadingDocuments = true;
        return this.favoritesStore.getFavorites()
          .then(action(data => {
            if (data) {
              this.allFavoriteMetadata = data ?? [];
              // Default to show favorites if the user has any and if there isn't a folder specified in the URL.
              if (this?.allFavoriteMetadata?.length && !landingPageUrlHasFolderId && !orgViewUrlHasFolderId && !orgViewUrlHasFolderAlias) {
                return this.setFavorites();
              } else {
                this.loadingDocuments = false;
              }
            }
          }))
          .catch(() => {
            this.loadingDocuments = false;
          });
      }
    }
    this.loading = false;
  }

  getAllSubFolders(node, subList) {
    if (!node?.subFolders) return;
    const subFolders = typeof node.subFolders === 'string' ? JSON.parse(node.subFolders) : node.subFolders;

    for (const subFolder of subFolders) {
      subList.push(subFolder.id);
      this.getAllSubFolders(subFolder, subList);
    }
  }

  updateExpandedNodes(node) {
    const index = this.expandedNodes.indexOf(node.id, 0);
    if (index > -1) {
      this.expandedNodes.splice(index, 1);
      const closeList: string[] = [];
      this.getAllSubFolders(node, closeList);
      for (const closeItem of closeList) {
        const subIndex = this.expandedNodes.indexOf(closeItem, 0);
        if (subIndex > -1) {
          this.expandedNodes.splice(subIndex, 1);
        }
      }
    } else {
      this.expandedNodes.push(node.id);
    }
  }

  @action.bound
  handleFolderSelected(node): any {
    if (!this.expandedMap.has(node.id)) {
      const newSelectedFolder = new Folder(node);
      this.expandedMap.set(node.id, newSelectedFolder);
      for (const sub of newSelectedFolder.subFolders) {
        this.expandedMap.set(sub.id, new Folder(sub));
      }
    }
    this.updateExpandedNodes(node);
    this.appStore.setSelectedFolder(node);
    let deSelectedClass: any = document.querySelectorAll('[class$=selected]');
    if (this.selectedFolder === node && deSelectedClass.length) {
      deSelectedClass = deSelectedClass[0].className.replace('Mui-selected', '');
      deSelectedClass.replace('Mui-expanded', '');
      document.querySelectorAll('[class$=Mui-selected]')[0].className = deSelectedClass;
      this.selectedFolder = undefined;
      this.appStore.selectedFolder = undefined;
    } else {
      this.appStore?.setCurrentPageIndex(1);
      this.selectedFolder = node;

      // Check if each newly selected folder has been favorited or not.
      this.allFavoriteMetadata = this.favoritesStore?.allFavoriteMetadata ?? [];
      this.favoritesStore = new FavoritesStore(this.appStore, this.selectedFolder?.id ?? '', 'folder', this.selectedFolder ?? {});
      this.favoritesStore.allFavoriteMetadata = this.allFavoriteMetadata;

      // Get Folder data.
      this.getFilesUnderFolder(node?.id);
    }
  }

  @action.bound
  onGetFilesSuccess(documents, metaData) {
    this.selectedFolder!.metaData = new EntityMetadata(metaData);
    this.appStore.isRelated = metaData.isRelated;
    this.documentWrapper = new DocumentWrapper(false, documents.documents, this.selectedFolder, documents.metaData);

    if (this.selectedFolder) {
      const folderPath = this.getFolderPath();
      // Landing page
      if (this.appStore.rootFolderId === '') this.appStore.navigateTo(NAVIGATION.LANDING_PAGE.LINK(folderPath));
      // Org view pretty URL
      else if (this.selectedFolder.folderAlias && this.selectedFolder.folderAlias.length > 0) {
        this.appStore.navigateTo(NAVIGATION.DASHBOARD_PAGE.ALIAS_LINK(this.selectedFolder.folderAlias));
      } else this.appStore.navigateTo(NAVIGATION.DASHBOARD_PAGE.LINK(folderPath)); // Org view
    }
  }

  @action.bound
  onError(e: ErrorModel) {
    this.loading = false;
    this.appStore.appToastAlertStore.addToastAlert(e, 'error');
  }

  @action.bound
  onFileRetrivalError(e: ErrorModel) {
    this.documentWrapper = new DocumentWrapper(false, [], this.selectedFolder);
    this.onError(e);
  }

  getFolderPath(): string {
    if (!this.selectedFolder) return '';
    const parentFolders = this.selectedFolder?.metaData?.parentFolders;
    let folderPath = '';

    if (typeof parentFolders === 'string') {
      folderPath = JSON.parse(parentFolders).reduce((sum: string, folder: Folder) => `${sum}${folder.id}/`, '');
    }

    return `${folderPath}${this.selectedFolder.id}`;
  }

  // Given a folder ID, calls api to retrieve documents.
  // Optionally takes pagination parameters.
  numCurrentFetches = 0;
  @action.bound
  async getFilesUnderFolder(folderId: string, paginationPageNumber = 1, paginationPageSize = JSON.parse(this.appStore.paginationPageSize.value || '10'), searchTitle = '', documentType: string[] = ['OTHER', 'POLICY', 'STANDARD', 'PROCEDURE', 'GUIDELINE', 'TRANSCLUDE']): Promise<void> {
    if (folderId) {
      this.documentWrapper = new DocumentWrapper(true, [], this.selectedFolder);

      const params = {
        searchQuery: {
          documentType: ['OTHER', 'POLICY', 'STANDARD', 'PROCEDURE', 'GUIDELINE', 'TRANSCLUDE'],
          instanceType: ['publication_and_draft'],
          hideSupplemental: true,
          hideTranslation: true,
          size: paginationPageSize,
          from: (paginationPageNumber - 1) * paginationPageSize,
          sortBy: 'title',
          status: ['active'],
          getDocTitle: true,
        },
      };
      if (this.includeSubFolders) {
        params.searchQuery['fullPath'] = [folderId];
      } else {
        params.searchQuery['parentFolderId'] = [folderId];
      }
      if (searchTitle) {
        params.searchQuery['title'] = searchTitle;
      }
      if (documentType.length === 0) {
        params.searchQuery['documentType'] = [];
      }
      if (documentType) {
        params.searchQuery['documentType'] = documentType;
      }
      try {
        this.numCurrentFetches++;
        const response = await this.appStore.api.mutate(queryDocuments, params);
        this.appStore.setCurrentPageIndex(paginationPageNumber);
        const searchDocuments = JSON.parse(response.data.queryDocuments).body.documents;
        const folderDocuments: any[] = searchDocuments.map(doc => ({
          id: doc._source.documentId,
          title: doc._source.title,
          description: doc._source.description,
          documentType: doc._source.documentType,
          isTemplate: doc._source.isTemplate,
          createdBy: doc._source.createdBy,
          createdOn: doc._source.createdOn,
          version: doc._source.version,
          rsMedata: doc._source.relationshipServiceMetadata,
          rsTitle: doc._source.docTitle,
        }));

        const documentResponse = {
          documents: folderDocuments,
          metaData: {
            pageNumber: paginationPageNumber,
            pageSize: paginationPageSize,
            totalItems: JSON.parse(response.data.queryDocuments).body.total,
            totalPages: Math.ceil(JSON.parse(response.data.queryDocuments).body.total / paginationPageSize),
          },
        };

        // This statement prevents wrong data being shown if user selected multiple folders quickly
        // The incoming data is ignored until the final fetch
        if (this.numCurrentFetches < 2) {

          const isFavoritesFolder: boolean = this.selectedFolder?.id === 'FAVORITES';
          if (isFavoritesFolder) {
            this.loading = false;
            return;
          }

          const metadata = await this.appStore.api.query(getFolderMetadata, { folderId });
          this.onGetFilesSuccess(documentResponse, metadata.data.getFolderMetadata);
        }

      } catch (e: any) {
        if (e.errors) this.onFileRetrivalError(e.errors[0]);
      } finally {
        this.numCurrentFetches--;
      }
    }
  }

  // Calculates the values for tree defaults based on URL.
  @action
  getTreeViewDefaults(landingPageUrlHasFolderId: boolean, orgViewUrlHasFolderId: boolean): void {
    const defaultExpanded: string[] = [];
    let defaultSelected = '';

    if (landingPageUrlHasFolderId || orgViewUrlHasFolderId) {
      const url = landingPageUrlHasFolderId ? window.location.href.split('#folderId=') : window.location.href.split('folder/');
      const folderIds: string | string[] = url[url.length - 1];
      const foldersFromUrl = folderIds.split('/').filter(Boolean);
      this.performFolderSelect(defaultSelected, foldersFromUrl).then(action(value => {
        defaultSelected = value ?? '';
        this.treeViewDefaults = {
          defaultExpanded,
          defaultSelected,
        };
      }));
    } else {
      this.treeViewDefaults = {
        defaultExpanded,
        defaultSelected,
      };
    }
  }

  @action
  performFolderSelect(defaultSelected, parentFolderList: string[]): any {
    if (defaultSelected === '') {
      let defaultSelectedNode: any = null;
      return this.appStore.api.query(getFolders, { loadAll: false, accessTypes: 'read,write,admin', folderIds: parentFolderList.join() })
        .then(action(folder => {
          if (folder?.data?.getFolders) {
            const data = folder.data.getFolders.reverse();
            defaultSelectedNode = new Folder(data[data.length - 1]);
            this.expandedNodes = parentFolderList;
            let depth = 1;
            const searchTree = (folders: Folder[]) => {
              for (let i = 0; i < folders.length; i++) {
                if (folders[i].id === parentFolderList[i]) {
                  const dataSubFolders = JSON.parse(data[i].subFolders);
                  folders[i].subFolders = dataSubFolders;
                  this.expandedNodes = [...this.expandedNodes, folders[i].id];
                  depth++;

                  if (depth <= parentFolderList.length) {
                    searchTree(dataSubFolders);
                  } else {
                    return folders;
                  }
                }
              }
            };

            searchTree(this.folders);
          }

          return defaultSelectedNode?.id;
        }))
        .catch(e => this.onError(e));
    }
  }

  /**
   * Displays the list of favorited documents and folders.
   * Handles pagination changes.
   */
  @action
  showFavorites(): void {
    this.loadingDocuments = true;
    this.appStore.setCurrentPageIndex(1);

    this.favoritesStore.getFavorites()
      .then(action(data => {
        this.allFavoriteMetadata = data ?? [];
        this.setFavorites();
      }));
  }

  @action
  setFavorites(): any {
    const favoritesFolder = {
      id: 'FAVORITES',
      folderName: 'Favorites',
      subFolders: [],
      description: 'This is a list of all the documents and folders that you have favorited.',
    };

    const pageSize = parseInt(this.appStore.paginationPageSize.value ?? '1');
    const length = this?.allFavoriteMetadata?.length ?? 0;
    const metaData = {
      totalItems: length,
      totalPages: Math.ceil(length / pageSize),
    };

    const index = this.appStore.paginationPageNumber * pageSize;
    const documents = this.allFavoriteMetadata
      .map(item => {
        item.id = item.entityId;
        return item;
      })
      .sort((a: FavoriteMetadata, b: FavoriteMetadata) => {
        const aTitle = a.title[0].toLowerCase();
        const bTitle = b.title[0].toLowerCase();
        if (a.entityType !== 'folder' && b.entityType === 'folder') return 1;
        if (aTitle < bTitle || (a.entityType === 'folder' && b.entityType !== 'folder')) return -1;
        if (aTitle > bTitle) return 1;
        return 0;
      })
      .slice(index - pageSize, index);

    this.selectedFolder = new Folder(favoritesFolder);
    const documentWrapper = new DocumentWrapper(false, documents, this.selectedFolder, metaData);
    this.documentWrapper = documentWrapper;
    this.loadingDocuments = false;
    return documentWrapper;
  }

  @action
  handleFavoritesClick(data: AllFavoriteMetadata): void {
    this.allFavoriteMetadata = data ?? [];
  }
}
